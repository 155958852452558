<div class="notification-box" (click)="openBookMark()">
  <app-feather-icons [icon]="'star'"></app-feather-icons>
</div>
<div
  class="onhover-show-div bookmark-flip"
  [class.active]="bookmarkFlip || bookmark"
>
  <div class="flip-card">
    <div class="flip-card-inner" [class.flipped]="bookmarkFlip">
      <div class="front">
        <ul class="droplet-dropdown bookmark-dropdown">
          <li class="gradient-primary">
            <app-feather-icons [icon]="'star'"></app-feather-icons>
            <h6 class="f-18 mb-0">Bookmark</h6>
          </li>
          <li class="custom-scrollbar">
            <div class="row">
              <div class="col-4 text-center" *ngFor="let item of bookmarkItems">
                <a
                  placement="top"
                  [ngbTooltip]="item?.title"
                  [routerLink]="item?.path"
                  routerLinkActive="item?.active"
                >
                  <app-feather-icons [icon]="item?.icon"></app-feather-icons>
                </a>
              </div>
            </div>
          </li>
          <li class="text-center" (click)="flipBookMark()">
            <button class="flip-btn" id="flip-btn">Add New Bookmark</button>
          </li>
        </ul>
      </div>
      <div class="back">
        <ul>
          <li>
            <div class="droplet-dropdown bookmark-dropdown flip-back-content">
              <input
                type="text"
                [(ngModel)]="text"
                [ngModelOptions]="{ standalone: true }"
                (input)="searchTerm(text)"
                placeholder="search..."
              />
              <div
                class="Typeahead-menu custom-scrollbar"
                [class.is-open]="searchResult"
                *ngIf="menuItems?.length"
                id="boomark-search-outer"
              >
                <div
                  class="ProfileCard u-cf"
                  *ngFor="let item of menuItems | slice : 0 : 8"
                >
                  <div class="ProfileCard-avatar">
                    <app-feather-icons
                      [icon]="item.icon"
                      class="header-search"
                    ></app-feather-icons>
                  </div>
                  <div class="ProfileCard-details">
                    <div class="ProfileCard-realName">
                      <a
                        [routerLink]="item?.path"
                        class="realname"
                        (click)="removeFix()"
                        >{{ item?.title }}</a
                      >
                      <span class="pull-right">
                        <a
                          href="javacript:void(0)"
                          (click)="addToBookmark(item)"
                        >
                          <i
                            class="fa fa-star-o f-18 mt-1"
                            [class.starred]="item.bookmark"
                          ></i>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">
                <div class="tt-dataset tt-dataset-0">
                  <div class="EmptyMessage">
                    Opps!! There are no result found.
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li (click)="flipBookMark()">
            <button class="d-block flip-back" id="flip-back">Back</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
