import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, from, of, throwError } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";
import { HttpService } from "./http.service";
import { environmentqa } from "src/environments/environment.qa";
import { AuthService } from "./firebase/auth.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HttpErrorInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const callNext = () => next.handle(req);
    let user = this.auth.getUserFromStorage();
    let expireTime = new Date(user?.data?.expiration);
    let noAction = req.url.indexOf("/api/Auth") !== -1;

    return callNext().pipe(
      catchError((error) => {
        if (!noAction && error.status == 401) {
          return this.auth.refreshToken().pipe(
            switchMap((res: any) => {
              const refreshedUser: any = res;
              sessionStorage.setItem("user", JSON.stringify(refreshedUser));
              sessionStorage.setItem(
                "token",
                JSON.stringify(refreshedUser.data.token)
              );
              sessionStorage.setItem(
                "refreshToken",
                JSON.stringify(refreshedUser.data.refreshToken)
              );
              user = this.auth.getUserFromStorage();
              const updatedRequest = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${refreshedUser.data.token}`,
                },
              });
              return next.handle(updatedRequest);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }
}
