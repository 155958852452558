<div id="print-section">
  <div class="provizyon-form">
    <div
      class="provizyon-form__header d-flex justify-content-between align-items-center"
    >
      <div class="provizyon-form__header__logo">
        <img src="../../../assets/images/logo_mavi.svg" />
      </div>
      <div class="provizyon-form__header__title">
        <p>SHY PROVİZYON DOKÜMAN FORMU</p>
      </div>
    </div>
    <div
      class="provizyon-form__header-bar d-flex justify-content-between align-items-center"
    >
      <div class="provizyon-form__header-bar__title">
        <p>ÖZEL MEMORİAL ATAŞEHİR HASTANESİ</p>
      </div>
      <button
        class="provizyon-form__header-bar__print-button"
        [useExistingCss]="true"
        printSectionId="print-section"
        ngxPrint
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>498A4435-42FA-4DDB-98EF-FA237E83C115</title>
          <defs>
            <rect id="path-1" x="0" y="0" width="20" height="20"></rect>
          </defs>
          <g
            id="UI-Kit"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Style-Sheet"
              transform="translate(-229.000000, -5053.000000)"
            >
              <g id="Group-21" transform="translate(144.000000, 5038.000000)">
                <g id="icon/print" transform="translate(83.000000, 13.000000)">
                  <g
                    id="icon/Printer"
                    transform="translate(2.000000, 2.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <use xlink:href="#path-1"></use>
                    </mask>
                    <g id="Canvas"></g>
                    <path
                      d="M2.66666667,5.41666667 L4.5,5.41666667 L4.5,0.833333333 L15.5,0.833333333 L15.5,5.41666667 L17.3333333,5.41666667 C18.3458833,5.41666667 19.1666667,6.2374775 19.1666667,7.25 L19.1666667,14.5833333 C19.1666667,15.5958833 18.3458833,16.4166667 17.3333333,16.4166667 L15.5,16.4166667 L15.5,19.1666667 L4.5,19.1666667 L4.5,16.4166667 L2.66666667,16.4166667 C1.65414417,16.4166667 0.833333333,15.5958833 0.833333333,14.5833333 L0.833333333,7.25 C0.833333333,6.2374775 1.65414417,5.41666667 2.66666667,5.41666667 Z M13.6666667,5.41666667 L6.33333333,5.41666667 L6.33333333,2.66666667 L13.6666667,2.66666667 L13.6666667,5.41666667 Z M17.3333333,7.25 L17.3333333,14.5833333 L15.5,14.5833333 L15.5,12.75 L4.5,12.75 L4.5,14.5833333 L2.66666667,14.5833333 L2.66666667,7.25 L17.3333333,7.25 Z M13.6666667,14.5833333 L6.33333333,14.5833333 L6.33333333,17.3333333 L13.6666667,17.3333333 L13.6666667,14.5833333 Z M16.4166667,9.08333333 C16.4166667,8.57705833 16.006275,8.16666667 15.5,8.16666667 C14.993725,8.16666667 14.5833333,8.57705833 14.5833333,9.08333333 C14.5833333,9.58960833 14.993725,10 15.5,10 C16.006275,10 16.4166667,9.58960833 16.4166667,9.08333333 Z"
                      id="Shape"
                      fill="#FFFFFF"
                      mask="url(#mask-2)"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
    <div class="provizyon-form__content">
      <p></p>
      <p class="provizyon-form__content__title">Paket Bilgileri</p>
      <div class="d-flex">
        <table class="table-top w-50 mr-1">
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Provizyon No</span
              ><span>:</span><span class="row-text">8501</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Üye Sicil No</span
              ><span>:</span><span class="row-text">Murat Aksoy</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >T.C. Kimlik No</span
              ><span>:</span><span class="row-text">27220928136</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Doğum Tarihi</span
              ><span>:</span><span class="row-text">07.12.1984</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title">Üye Statü</span>
              <span>:</span><span class="row-text">Çalışan</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title">Hizmet Alan</span
              ><span>:</span><span class="row-text">Kendisi</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Kapsam Bilgisi</span
              ><span>:</span><span class="row-text">Kısmi Çalışan</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Provizyon Tarihi</span
              ><span>:</span><span class="row-text">15.11.2022 - 12:00</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Provizyon Durumu</span
              ><span>:</span><span class="row-text">Fatura için bekliyor</span>
            </td>
          </tr>
        </table>
        <table class="table-top w-50 ml-1">
          <tr>
            <td>
              <span style="min-width: 131px" class="row-title"
                >Tevkifatlı mı?</span
              ><span>:</span><span class="row-text">Hayır</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 131px" class="row-title"
                >KDV'siz Tutar</span
              ><span>:</span><span class="row-text">100.000,00</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 131px" class="row-title"
                >Hesaplanan KDV(%8)</span
              ><span>:</span><span class="row-text">100.000,00</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 131px" class="row-title"
                >Vergiler Dahil Tutar</span
              ><span>:</span><span class="row-text">100.000,00</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 131px" class="row-title"
                >Tevkifat Oranı</span
              >
              <span>:</span><span class="row-text">100.000,00</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 131px" class="row-title"
                >Tevkifat Tutarı</span
              ><span>:</span><span class="row-text">100.000,00</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 131px" class="row-title"
                >Tevkifatlı Ödenecek Tutar</span
              ><span>:</span><span class="row-text">100.000,00</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 131px" class="row-title"
                >Red Tutar KDV'li</span
              ><span>:</span><span class="row-text">100.000,00</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 131px" class="row-title danger-color"
                >*SHY Katkı Payı Tutarı</span
              ><span>:</span
              ><span class="row-text danger-color">100.000,00</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 131px" class="row-title danger-color"
                ><b>*Katkı Payı Tutarını Üyeden Tahsil Ediniz!</b></span
              >
            </td>
          </tr>
        </table>
      </div>
      <table class="table-bottom w-100 m-b-15 m-t-15">
        <tr>
          <th>Branş</th>
          <th>Doktor</th>
          <th>SGK Anlaşmalı mı?</th>
        </tr>
        <tr>
          <td>
            <span>Kulak Burun Boğaz</span>
          </td>
          <td>
            <span>Op. Dr. Sezen Yavuz</span>
          </td>
          <td>
            <span>Evet</span>
          </td>
        </tr>
      </table>
      <table class="table-bottom w-100 m-b-15">
        <tr>
          <th style="width: 55px">Sıra No</th>
          <th style="width: 125px">ICD Kod</th>
          <th>Ad</th>
        </tr>
        <tr>
          <td>
            <span>1</span>
          </td>
          <td>
            <span>C50</span>
          </td>
          <td>
            <span>Meme Maling</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>2</span>
          </td>
          <td>
            <span>A10</span>
          </td>
          <td>
            <span>Meme Maling</span>
          </td>
        </tr>
      </table>
      <table class="table-bottom w-100 m-b-15 td-padding-5">
        <tr>
          <th style="width: 55px">Sıra No</th>
          <th style="width: 125px">Kod</th>
          <th>Ad</th>
          <th>KDV'li Tutar</th>
          <th>KDV'siz Tutar</th>
          <th>Ödenecek Tevkifatlı Tutar</th>
          <th class="danger-color">*Üye Katkı Payı</th>
          <th>SGK Tutarı</th>
          <th>Red Tutar KDV'li</th>
          <th>Tevkifatlı mı?</th>
        </tr>
        <tr>
          <td>
            <span>1</span>
          </td>
          <td>
            <span>34.HB.00007</span>
          </td>
          <td>
            <span>Prokalsotnin</span>
          </td>
          <td>
            <span>10.000,00</span>
          </td>
          <td>
            <span>100.000,00</span>
          </td>
          <td>
            <span>100.000,00</span>
          </td>
          <td>
            <span class="danger-color">100.000,00</span>
          </td>
          <td>
            <span>100.000,00</span>
          </td>
          <td>
            <span>100.000,00</span>
          </td>
          <td>
            <span>Evet</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>2</span>
          </td>
          <td>
            <span>34.HB.00007</span>
          </td>
          <td>
            <span>Prokalsotnin</span>
          </td>
          <td>
            <span>10.000,00</span>
          </td>
          <td>
            <span>100.000,00</span>
          </td>
          <td>
            <span>100.000,00</span>
          </td>
          <td>
            <span class="danger-color">100.000,00</span>
          </td>
          <td>
            <span>100.000,00</span>
          </td>
          <td>
            <span>100.000,00</span>
          </td>
          <td>
            <span>Hayır</span>
          </td>
        </tr>
      </table>
      <table class="table-toplam float-right">
        <tr>
          <td><b>Toplam</b></td>
          <td>100.000,00</td>
        </tr>
      </table>
    </div>
  </div>
</div>
