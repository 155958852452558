import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { CaptchaEmitService } from "src/app/shared/services/emitService/captcha-emit/captcha-emit.service";
import { GenelService } from "src/app/shared/services/genel/genel/genel.service";
import { HttpService } from "src/app/shared/services/http.service";
import { LoginIslemleriService } from "src/app/shared/services/login-islemleri/login-islemleri.service";
import { environmentqa } from "src/environments/environment.qa";
@Component({
  selector: "app-sifre-islemleri",
  templateUrl: "./sifre-islemleri.component.html",
  styleUrls: ["./sifre-islemleri.component.scss"],
})
export class SifreIslemleriComponent implements OnInit {
  // baseUrl = window.sessionStorage.getItem('apiUrl')
  baseUrl = environmentqa.apiUrl;
  @Input() loginState;
  @Input() loginType;
  @Input() user;
  kullaniciAdi;
  telSon4Hane;
  smsKodu;
  sifre1;
  sifre2;
  captchaText;
  code;
  public loginForm: FormGroup;
  constructor(
    private captchaEmit: CaptchaEmitService,
    private fb: FormBuilder,
    private router: Router,
    private httpClient: HttpClient,
    private loginIslemleriService: LoginIslemleriService,
    private genel: GenelService,
    private http: HttpService
  ) {
    fetch("/assets/config/config.json")
      .then((res) => res.json())
      .then((jsonData) => {
        environmentqa.apiUrl = jsonData.apiUrl;
      });
  }
  public show: boolean = false;
  ngOnInit(): void {
    this.emitCode();
    this.captcha();
    this.valitadion();
    this.loginForm = this.fb.group({
      kAdi: ["", [Validators.required]],
      no4Hane: ["", Validators.required],
      recaptcha: ["", Validators.required],
    });
  }

  sifreGoster($event) {
    this.show = $event;
  }
  valitadion() {}
  emitCode() {
    this.captchaEmit.getCodeEmit.subscribe((res) => {
      this.code = res;
    });
  }
  captchaData: any;
  key: any;
  captchaDeger;
  async captcha() {
    this.http.httpGet("Auth/CaptchaUretici").then((res) => {
      this.captchaData = "data:image/png;base64," + res?.data.deger;
      this.key = res?.data.key;
      this.captchaDeger = res?.data.deger;
    });
  }
  async sifremiUnuttum() {
    var reqHeader = new HttpHeaders({
      //'Content-Type': 'application/json',
      accept: "*/*",
    });

    this.httpClient
      .get(
        this.baseUrl +
          `/Auth/SifremiUnuttum?KullaniciAdi=${this.loginForm.value["kAdi"]}&TelSonDortHane=${this.loginForm.value["no4Hane"]}&CaptchaKey=${this.key}&CaptchaDeger=${this.loginForm.value["recaptcha"]}`,
        { headers: reqHeader }
      )
      .subscribe(
        (res) => {
          this.genel.mesajKontrol(res["basariliMi"], res["mesaj"][0]);
          if (res["basariliMi"] == true) {
            this.loginType = 2;
          } else {
            this.loginType = 1;
          }
        },
        (err) => {
          this.genel.mesajError();
        }
      );
    // event.preventDefault();
    // if (this.captchaText == this.code) {

    //     this.captchaEmit.setOptikEmitServis(null)
    //     this.captchaText = null
    // } else {
    //     this.genel.mesajKontrol(false, "Güvenlik Kodu Yanlış!")
    //     this.captchaEmit.setOptikEmitServis(null)
    //     this.captchaText = null

    // }
  }
  smsToken;
  async smsKoduDogrulu() {
    this.httpClient
      .get(
        this.baseUrl +
          `/Auth/SmsKoduDogrula?KullaniciAdi=${this.kullaniciAdi}&SmsKodu=${this.smsKodu}`
      )
      .subscribe(
        (res) => {
          // this.genel.mesajKontrol(res['basariliMi'], res['mesaj'][0])
          if (res["basariliMi"] == true) {
            this.smsToken = res["data"].token;
            this.loginType = 5;
            this.genel.mesajKontrol(
              res["basariliMi"],
              "Yeni Şifre Belirleyiniz."
            );
          } else {
            this.genel.mesajKontrol(false, "SMS kodunu yanlış girdiniz.");
            this.loginType = 2;
          }
        },
        (err) => {}
      );
  }

  async ilkGirisKontrol() {
    var reqHeader = new HttpHeaders({
      //'Content-Type': 'application/json',
      Authorization: "Bearer " + this.user.data.token,
    });
    if (this.sifre1 == this.sifre2) {
      let data = {
        yeniSifre: this.sifre1,
      };
      this.httpClient
        .put(this.baseUrl + "/LoginIslemleri/IlkGirisSifreGuncelle", data, {
          headers: reqHeader,
        })
        .subscribe(
          (res) => {
            this.genel.mesajKontrol(res["basariliMi"], res["mesaj"][0]);

            if (res["basariliMi"] == true) {
              location.reload();
            }
          },
          (err) => {}
        );
    } else {
      this.genel.mesajKontrol(
        false,
        "Şifreler Aynı Değil, Lütfen Kontrol Ediniz."
      );
    }
  }

  async gunKontrol() {
    var reqHeader = new HttpHeaders({
      //'Content-Type': 'application/json',
      Authorization: "Bearer " + this.user.data.token,
    });
    if (this.sifre1 == this.sifre2) {
      let data = {
        yeniSifre: this.sifre1,
      };
      this.httpClient
        .put(this.baseUrl + "/LoginIslemleri/GunKontrolSifreGuncelle", data, {
          headers: reqHeader,
        })
        .subscribe(
          (res) => {
            this.genel.mesajKontrol(res["basariliMi"], res["mesaj"][0]);

            if (res["basariliMi"] == true) {
              location.reload();
            }
          },
          (err) => {}
        );
    } else {
      this.genel.mesajKontrol(
        false,
        "Şifreler Aynı Değil, Lütfen Kontrol Ediniz."
      );
    }
  }

  async smsKoduIleSifreGuncelle() {
    var reqHeader = new HttpHeaders({
      //'Content-Type': 'application/json',
      Authorization: "Bearer " + this.smsToken,
    });
    if (this.sifre1 == this.sifre2) {
      let data = {
        smsKod: this.smsKodu,
        yeniSifre: this.sifre1,
      };

      this.httpClient
        .put(this.baseUrl + "/LoginIslemleri/SmsKoduIleSifreGuncelle", data, {
          headers: reqHeader,
        })
        .subscribe(
          (res) => {
            this.genel.mesajKontrol(res["basariliMi"], res["mesaj"][0]);

            if (res["basariliMi"] == true) {
              location.reload();
            }
          },
          (err) => {
            this.genel.mesajError();
          }
        );
    } else {
      this.genel.mesajKontrol(
        false,
        "Şifreler Aynı Değil, Lütfen Kontrol Ediniz."
      );
    }
  }
}
