import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class VakifIcinOrtakService {

  constructor(private http: HttpService, private spinner: NgxSpinnerService) { }

  // intercept(
  //   request: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   this.spinner.show();
  //   return next.handle(request).pipe(
  //     finalize(() => {
  //       this.spinner.hide();
  //     })
  //   );
  // }

  provizyonKaydet(data) {
    return this.http.httpPost('VakifIcinOrtak/ProvizyonKaydet', data)
  }

  provizyonGetir(id) {
    return this.http.httpGet(`VakifIcinOrtak/ProvizyonGetir/${id}`)
  }
  provizyonSil(id) {
    return this.http.httpDelete(`VakifIcinOrtak/ProvizyonSil/${id}`)
  }

  provizyonKurumAciklamaGuncelleme(data) {
    return this.http.httpPut(`VakifIcinOrtak/ProvizyonKurumAciklamaGuncelle`, data)
  }

  islemTipKaydet(data) {
    return this.http.httpPost('VakifIcinOrtak/IslemTipKaydet', data)
  }

  islemTipGetir(faturaId, Id) {
    return this.http.httpGet(`VakifIcinOrtak/IslemTipGetir?ProvizyonFaturaID=${faturaId}&ID=${Id}`)
  }

  islemTipListesi(Id) {
    return this.http.httpGet(`VakifIcinOrtak/IslemTipListesi/${Id}`)
  }

  islemTipSil(faturaId, Id) {
    return this.http.httpDelete(`VakifIcinOrtak/IslemTipSil?ProvizyonFaturaID=${faturaId}&ID=${Id}`)
  }

  provizyonFaturaIslemTipKontrol(faturaId) {
    return this.http.httpGet(`VakifIcinOrtak/ProvizyonFaturaIslemTipKontrol/${faturaId}`)
  }

  islemTipIcd10Kaydet(data) {
    return this.http.httpPost('VakifIcinOrtak/IslemTipIcd10Kaydet', data)
  }
  islemTipGuncelle(data) {
    return this.http.put('VakifIcinOrtak/IslemTipGuncelle', data)
  }

  islemTipIcd10Sil(islemTipId, Id) {
    return this.http.httpDelete(`VakifIcinOrtak/IslemTipIcd10Sil?ProvizyonFaturaIslemTipID=${islemTipId}&ID=${Id}`)
  }

  islemTipIcd10Listesi(islemTipId) {
    return this.http.httpGet(`VakifIcinOrtak/IslemTipIcd10Listesi/${islemTipId}`)
  }

  ozelIslemTipSil(provizyonId, id) {
    return this.http.httpDelete(`VakifIcinOrtak/OzelIslemTipSil?ProvizyonFaturaID=${provizyonId}&ID=${id}`)
  }

  //--------------







}
