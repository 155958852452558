import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../components/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "Fatura-Provizyon",
    loadChildren: () =>
      import(
        "../../components/invoice-provision/invoice-provision.module"
      ).then((m) => m.InvoiceProvisionModule),
  },
  {
    path: "provizyon-kontrol",
    loadChildren: () =>
      import(
        "../../components/provizyon-kontrol/provizyon-kontrol.module"
      ).then((m) => m.ProvizyonKontrolModule),
  },
  {
    path: "provizyon-paket",
    loadChildren: () =>
      import("../../components/provizyon-paket/provizyon-paket.module").then(
        (m) => m.ProvizyonPaketModule
      ),
  },
  {
    path: "seansli-islem-talep",
    loadChildren: () =>
      import(
        "../../components/seansli-islem-talep/seansli-islem-talep.module"
      ).then((m) => m.SeansliIslemTalepModule),
  },
  {
    path: "seansli-islemler",
    loadChildren: () =>
      import(
        "../../components/invoice-provision/seansli-islemler/seansli-islemler.module"
      ).then((m) => m.SeansliIslemlerModule),
  },
  {
    path: "optik-provizyon",
    loadChildren: () =>
      import("../../components/provizyon-optik/provizyon-optik.module").then(
        (m) => m.ProvizyonOptikModule
      ),
  },
  {
    path: "yatarak-tedavi",
    loadChildren: () =>
      import(
        "../../components/invoice-provision/yatarak-tedavi/yatarak-tedavi.module"
      ).then((m) => m.YatarakTedaviModule),
  },
  {
    path: "dis-tedavi",
    loadChildren: () =>
      import(
        "../../components/invoice-provision/dis-tedavi/dis-tedavi.module"
      ).then((m) => m.DisTedaviModule),
  },

  {
    path: "iade-provizyon",
    loadChildren: () =>
      import(
        "../../components/iade-provizyonlar/iade-provizyonlar.module"
      ).then((m) => m.IadeProvizyonlarModule),
  },
  {
    path: "odenen-iade",
    loadChildren: () =>
      import("../../components/odenen-iade/odenen-iade.module").then(
        (m) => m.OdenenIadeModule
      ),
  },
  {
    path: "yonetici",
    loadChildren: () =>
      import("../../components/yönetici/yonetici.module").then(
        (m) => m.YoneticiModule
      ),
  },
  {
    path: "islem-tip-kontrol",
    loadChildren: () =>
      import(
        "../../components/islem-tip-kontrol/islem-tip-kontrol.module"
      ).then((m) => m.IslemTipKontrolModule),
  },
  {
    path: "yardim-dokuman",
    loadChildren: () =>
      import(
        "../../components/yardim-dokumanlari/yardim-dokumanlari.module"
      ).then((m) => m.YardimDokumanlariModule),
  },
];
