<!-- <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="large"
  color="#fff"
  type="square-loader"
  [fullScreen]="true"
  ><p style="color: white">Lütfen Bekleyin! - Yükleniyor...</p></ngx-spinner
> -->

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-spin-clockwise"
  [fullScreen]="true"
  ><p style="color: white">Yükleniyor. Lütfen Bekleyin.</p></ngx-spinner
>
