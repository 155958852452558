import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/firebase/auth.service";

@Injectable({
  providedIn: "root",
})
export class SecureInnerPagesGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let user = JSON.parse(sessionStorage.getItem("user"));
    if (this.authService.isLoggedIn) {
      this.router.navigate(["/dashboard/default"]);
    }
    return true;
  }
}
