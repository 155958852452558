<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div class="col-12">
          <div>
            <a class="logo" routerLink="/">
              <img
                class="img-fluid for-light"
                src="../../../assets/images/login/login-logo.svg"
                alt="looginpage"
              />
              <p class="login-mega-title">Emekli Sandığı Vakfı</p>
              <!-- <img class="img-fluid for-dark" src="https://www.halkvakif.org/images/halkvakif_logo.png" alt="looginpage"> -->
            </a>
          </div>
          <div
            class="login-main"
            *ngIf="
              loginState == undefined || loginState == null || loginState == 0
            "
          >
            <form class="theme-form" [formGroup]="loginForm">
              <div class="theme-form__top">
                <h2>Halk Bankası Emekli Sandığı Provizyon Sistemi</h2>
                <p class="m-0">
                  Giriş yapabilmek için Kullanıcı Adı ve Şifre bilgilerinizi
                  giriniz.
                </p>
              </div>
              <hr class="card-hr m-3" />
              <div class="theme-form__content">
                <div class="form-group mb-3">
                  <label class="label">Kullanıcı Adı</label>
                  <input
                    class="form-control"
                    type="email"
                    required=""
                    [(ngModel)]="email"
                    placeholder="Kullanıcı Adı"
                    formControlName="email"
                  />
                  <div
                    *ngIf="
                      loginForm.controls.email.touched &&
                      loginForm.controls.email.errors?.required
                    "
                    class="text text-danger mt-1"
                  >
                    Kullanıcı Adı Boş Geçilemez.
                  </div>
                  <div
                    *ngIf="
                      loginForm.controls.email.touched &&
                      loginForm.controls.email.errors?.email
                    "
                    class="text text-danger mt-1"
                  >
                    Kullanıcı Adı Yanlış.
                  </div>
                </div>
                <div class="form-group mb-3">
                  <label class="label">Şifre</label>
                  <input
                    class="form-control"
                    [type]="show ? 'text' : 'password'"
                    formControlName="password"
                    [(ngModel)]="password"
                    required=""
                    placeholder="*********"
                  />
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                    <span class="show"
                      ><i
                        class="far fa-light fa-eye-slash text-dark"
                        style="cursor: pointer"
                      ></i
                    ></span>
                  </div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show">
                    <span class="Hide"
                      ><i
                        class="far fa-light fa-eye text-dark"
                        style="cursor: pointer"
                      ></i
                    ></span>
                  </div>
                  <div
                    *ngIf="
                      loginForm.controls.password.touched &&
                      loginForm.controls.password.errors?.required
                    "
                    class="text text-danger mt-1"
                  >
                    Şifre boş geçilemez.
                  </div>
                </div>
                <div
                  class="d-flex justify-content-end text-dec"
                  style="margin-top: -21px !important; margin-bottom: 8px"
                >
                  <a class="text-blue" (click)="siffremiUnuttum()"
                    >Şifremi Unuttum</a
                  >
                </div>
                <div class="form-group">
                  <img src="{{ captchaData }}" />
                  <a class="ml-3 mt-auto mb-auto" (click)="captcha()">
                    <i
                      class="icon-reload mt-auto mb-auto"
                      style="zoom: 1.5; cursor: pointer"
                    ></i
                  ></a>
                  <div>
                    <input
                      type="text"
                      class="mt-3"
                      placeholder="Doğrulama Kodu"
                      formControlName="recaptcha"
                      [(ngModel)]="captchaText"
                    />
                  </div>
                  <div
                    *ngIf="
                      loginForm.controls.recaptcha.touched &&
                      loginForm.controls.recaptcha.errors?.required
                    "
                    class="text text-danger mt-1"
                  ></div>
                </div>

                <div class="form-group m-0 mt-3">
                  <button
                    [disabled]="loginForm.invalid"
                    class="btn btn-success btn-block"
                    [class.loader--text]="authService.showLoader"
                    (click)="login()"
                    type="button"
                  >
                    <span>{{ authService.showLoader ? "" : "Giriş" }}</span>
                  </button>
                </div>
              </div>
              <!-- <p class="mt-4 mb-0">Don't have account?
                <a [routerLink]="'/authentication/register/simple'" class="ml-2">Create Account</a>
              </p> -->
            </form>
          </div>
          <div *ngIf="loginState == 1 || loginState == 2">
            <app-sifre-islemleri
              [loginState]="loginState"
              [loginType]="loginType"
              [user]="user"
            ></app-sifre-islemleri>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
