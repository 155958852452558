import { Injectable } from "@angular/core";

import { environmentqa } from "../../../environments/environment.qa";

@Injectable({
  providedIn: "root",
})
export class AppInitService {
  constructor() {}

  Init() {
    return fetch("/assets/config/config.json")
      .then((res) => res.json())
      .then((jsonData) => {
        environmentqa.apiUrl = jsonData.apiUrl;
      });
  }
}
