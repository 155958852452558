import { HttpClient } from '@angular/common/http';
import { Injectable, Pipe } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { HttpService } from '../../http.service';

@Injectable({
  providedIn: 'root'
})
export class AyaktaTedaviShyService {

  constructor(private httpClient: HttpClient, private http: HttpService) { }



  ilacMalzemeListesi() {
    return this.http.httpGet('AyaktaTedaviShy/IlacMalzemeListesi')
  }

  ilacMalzemeKaydet(data) {
    return this.http.httpPost('AyaktaTedaviShy/IlacMalzemeKaydet', data)
  }

  muayeneVarMi() {
    return this.http.httpGet('AyaktaTedaviShy/MuayeneVarMi/1')
  }

  ayaktaTedaviHizmetListesi(provizyonId, islemTipId, teminatId) {
    return this.http.httpGet(`AyaktaTedaviShy/AyaktaTedaviHizmetListesi?ProvizyonFaturaID=${provizyonId}&ProvizyonFaturaIslemTipID=${islemTipId}&TeminatID=${teminatId}`)
  }

  provizyonFaturaAyaktaTedaviSilme(provizyonId, islemTipId, teminatId) {
    return this.http.httpDelete(`AyaktaTedaviShy/ProvizyonFaturaAyaktaTedaviSilme?ProvizyonFaturaID=${provizyonId}&ProvizyonFaturaIslemTipID=${islemTipId}&ID=${teminatId}`)
  }

  provizyonFaturaAyaktaTedaviListesi(islemTipId) {
    return this.http.httpGet(`AyaktaTedaviShy/ProvizyonFaturaAyaktaTedaviListesi/${islemTipId}`)
  }

  provizyonFaturaAyaktaTedaviKaydet(data) {
    return this.http.httpPost('AyaktaTedaviShy/ProvizyonFaturaAyaktaTedaviKaydet', data)
  }

  provizyonFaturaAyaktaTedaviGuncelle(data) {
    return this.http.httpPut('AyaktaTedaviShy/ProvizyonFaturaAyaktaTedaviGuncelle', data)
  }

  provizyonFaturaAyaktaTedaviGetir(islemTipId) {
    return this.http.httpGet(`AyaktaTedaviShy/ProvizyonFaturaAyaktaTedaviGetir/${islemTipId}`)
  }


  //-------------------


  sgkTutariHesapla(data) {
    return this.http.httpPut('AyaktaTedaviShy/SgkTutariHesapla', data)
  }

  sgkTutariSifirla(data) {
    return this.http.httpPut('AyaktaTedaviShy/SgkTutariSifirla', data)
  }
}
