import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OptikEmitService {

  constructor() { }



  private setOptikEmit = new BehaviorSubject<any>(undefined);
  public getOptikEmit: Observable<any> = this.setOptikEmit

  setOptikEmitServis(value) {
    this.setOptikEmit.next(value);
  }

  private setLensOptikEmit = new BehaviorSubject<any>(undefined);
  public getLensOptikEmit: Observable<any> = this.setLensOptikEmit

  setLensOptikEmitServis(value) {
    this.setLensOptikEmit.next(value);
  }
}
