import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { FullComponent } from "./shared/components/layout/full/full.component";
import { full } from "./shared/routes/full.routes";
import { content } from "./shared/routes/routes";

import { AdminGuard } from "./shared/guard/admin.guard";
import { ShyleSheetComponent } from "./components/shylesheet/shyle-sheet/shyle-sheet.component";
import { StyleSheetIkiComponent } from "./components/shylesheet/style-sheet-2/style-sheet-iki/style-sheet-iki.component";
import { StyleSheetUcComponent } from "./components/shylesheet/style-sheet-uc/style-sheet-uc.component";
import { SifreIslemleriComponent } from "./auth/login-islemleri/sifre-islemleri/sifre-islemleri.component";
import { Print1Component } from "./print-global/print1/print1.component";
import { Print2Component } from "./print-global/print2/print2.component";
import { Print3Component } from "./print-global/print3/print3.component";
import { Print4Component } from "./print-global/print4/print4.component";
import { Print5Component } from "./print-global/print5/print5.component";
import { Print6Component } from "./print-global/print6/print6.component";

import { Error404Component } from "./pages/error/error404/error404.component";
import { Error500Component } from "./pages/error/error500/error500.component";
import { CookieComponent } from "./cookie/cookie.component";
import { StyleSheetDortComponent } from "./components/shylesheet/style-sheet-dort/style-sheet-dort.component";
import { VersionComponent } from "./version/version/version.component";
const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard/default",
    pathMatch: "full",
  },
  {
    path: "auth/login",
    component: LoginComponent,
  },
  {
    path: "auth/forgot-password",
    component: SifreIslemleriComponent,
  },
  {
    path: "style",
    component: ShyleSheetComponent,
  },
  {
    path: "style2",
    component: StyleSheetIkiComponent,
  },
  {
    path: "style3",
    component: StyleSheetUcComponent,
  },
  {
    path: "style4",
    component: StyleSheetDortComponent,
  },
  {
    path: "cookies",
    component: CookieComponent,
  },
  {
    path: "version",
    component: VersionComponent,
  },
  // {
  //   path: 'yazdir/print1',
  //   component: Print1Component
  // },
  // {
  //   path: 'yazdir/print2',
  //   component: Print2Component
  // },
  // {
  //   path: 'yazdir/print3',
  //   component: Print3Component
  // },
  // {
  //   path: 'yazdir/print4',
  //   component: Print4Component
  // },
  // {
  //   path: 'yazdir/print5',
  //   component: Print5Component
  // },
  // {
  //   path: 'yazdir/print6',
  //   component: Print6Component,

  // },

  {
    path: "",
    component: ContentComponent,
    canActivate: [AdminGuard],
    children: content,
  },

  {
    path: "",
    component: FullComponent,
    canActivate: [AdminGuard],
    children: full,
  },
  {
    path: "**",
    redirectTo: "/error-page/error-404",
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
        relativeLinkResolution: "legacy",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
