<div
  class="blog4 col-12 text-center"
  style="display: inline-block; padding: 100px 300px 300px 300px"
>
  <h3>Halkvakıf Çerez Politikası</h3>
  <div style="overflow-x: auto">
    <p align="center" dir="ltr" style="text-align: center">
      <b
        ><u
          ><span style="font-size: 12pt"
            ><span style="line-height: 107%"
              ><span style="font-family: 'Times New Roman', serif"
                ><span style="color: #262626"
                  >TÜRKİYE HALK BANKASI A.Ş. EMEKLİ SANDIĞI VAKFI ÇEREZ
                  POLİTİKASI</span
                ></span
              ></span
            ></span
          ></u
        ></b
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="line-height: 150%"
        ><span style="font-size: 12pt"
          ><span style="line-height: 150%"
            ><span style="font-family: 'Times New Roman', serif"
              ><span style="color: #262626"
                >Bu politika kapsamında “Çerez” kavramı, çerez ve benzer amaca
                hizmet eden ürünleri ifade etmektedir.</span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="line-height: 150%"
        ><b
          ><u
            ><span style="font-size: 12pt"
              ><span style="line-height: 150%"
                ><span style="font-family: 'Times New Roman', serif"
                  ><span style="color: #262626">Çerez Nedir?</span></span
                ></span
              ></span
            ></u
          ></b
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="line-height: 150%"
        ><span style="font-size: 12pt"
          ><span style="line-height: 150%"
            ><span style="font-family: 'Times New Roman', serif"
              ><span style="color: #262626"
                >Çerez, bir siteyi ziyaret ettiğinizde sitenin bilgisayarınıza
                veya mobil cihazınıza kaydettiği küçük boyutlu bir metin
                dosyasıdır. Çerezler bir web sitesinin çalışması, daha verimli
                çalışması veya web sitesinin sahiplerine bilgi sağlamak için
                yaygın olarak kullanılmaktadır.</span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="line-height: 150%"
        ><span style="font-size: 12pt"
          ><span style="line-height: 150%"
            ><span style="font-family: 'Times New Roman', serif"
              ><span style="color: #262626"
                >Çerezler hakkında daha detaylı bilgi için
              </span></span
            ></span
          ></span
        ><a href="http://www.aboutcookies.org" target="" title=""
          ><span style="font-size: 12pt"
            ><span style="background: white"
              ><span style="line-height: 150%"
                ><span style="font-family: 'Times New Roman', serif"
                  >http://www.aboutcookies.org</span
                ></span
              ></span
            ></span
          ></a
        ><span style="font-size: 12pt"
          ><span style="background: white"
            ><span style="line-height: 150%"
              ><span style="font-family: 'Times New Roman', serif"
                ><span style="color: #333333"> ve </span></span
              ></span
            ></span
          ></span
        ><a href="http://www.allaboutcookies.org" target="" title=""
          ><span style="font-size: 12pt"
            ><span style="background: white"
              ><span style="line-height: 150%"
                ><span style="font-family: 'Times New Roman', serif"
                  >http://www.allaboutcookies.org</span
                ></span
              ></span
            ></span
          ></a
        ><span style="font-size: 12pt"
          ><span style="background: white"
            ><span style="line-height: 150%"
              ><span style="font-family: 'Times New Roman', serif"
                ><span style="color: #333333">
                  adreslerini ziyaret edebilirsiniz.</span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="line-height: 150%"
        ><b
          ><u
            ><span style="font-size: 12pt"
              ><span style="line-height: 150%"
                ><span style="font-family: 'Times New Roman', serif"
                  ><span style="color: #262626">Ne için kullanılır?</span></span
                ></span
              ></span
            ></u
          ></b
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="line-height: 150%"
        ><span style="font-size: 12pt"
          ><span style="line-height: 150%"
            ><span style="font-family: 'Times New Roman', serif"
              ><span style="color: #262626"
                >Çerezler bilgisayarlarda, telefonlarda ve diğer cihazlarda sizi
                tanımlayan bilgileri ve siteyi kullanırken yaptığınız
                hareketleri, kullanım alışkanlıklarınızı toplamak, tutmak ve
                devamında internet sitemizi iyileştirmek ve size özel
                kişiselleştirmek için kullanılır.
              </span></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr">
      <span style="font-size: 12pt"
        ><span style="line-height: 107%"
          ><span style="font-family: 'Times New Roman', serif"
            ><span style="color: #262626"
              >Biz de Türkiye Halk Bankası A.Ş. Emekli Sandığı Vakfı olarak
            </span></span
          ></span
        ></span
      ><span style="font-size: 12pt"
        ><span style="line-height: 107%"
          ><span style="font-family: 'Times New Roman', serif"
            >www.halkvakif.org
            <span style="color: #262626"
              >internet sayfasında,
              <span style="background: white"
                >site içeriği ile nasıl etkileşime geçtiğinizi tespit etmek </span
              >ve kullanıcı deneyiminizi geliştirebilmek için çeşitli çerezler
              kullanmaktayız.
            </span></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="line-height: 150%"
        ><span style="font-size: 12pt"
          ><span style="line-height: 150%"
            ><span style="font-family: 'Times New Roman', serif"
              ><span style="color: #262626"
                >Web sitemizde kullanılan çerezler hakkında daha detaylı bilgi
                edinmek için Çerez Tablomuzu inceleyebilirsiniz.
              </span></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="line-height: 150%"
        ><b
          ><u
            ><span style="font-size: 12pt"
              ><span style="line-height: 150%"
                ><span style="font-family: 'Times New Roman', serif"
                  ><span style="color: #262626"
                    >Birinci Taraf ve Üçüncü Taraf Çerezler</span
                  ></span
                ></span
              ></span
            ></u
          ></b
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="font-family: Times New Roman, Times, serif"
        ><span style="font-size: 16px"
          ><span style="background: white"
            ><span style="line-height: 150%"
              ><span style="vertical-align: baseline"
                ><span style="color: #262626"
                  >Türkiye Halk Bankası A.Ş. Emekli Sandığı Vakfı’ye ait </span
                >www.halkvakif.org
                <span style="color: #262626"
                  >internet sayfasında hem birinci taraf hem de üçüncü taraf
                  çerezler kullanılmaktadır.</span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="font-family: Times New Roman, Times, serif"
        ><span style="font-size: 16px"
          ><span style="background: white"
            ><span style="line-height: 150%"
              ><span style="vertical-align: baseline"
                ><span style="color: #262626"
                  >Birinci taraf çerezler, şirketimiz tarafından </span
                >www.halkvakif.org
                <span
                  style="
                    border: none windowtext 1pt;
                    color: #262626;
                    padding: 0cm;
                  "
                  ><span style="text-decoration: none"
                    >domainine yerleştirilmiş ve sadece
                  </span></span
                ><span style="color: #262626"
                  >Türkiye Halk Bankası A.Ş. Emekli Sandığı Vakfı<span
                    style="
                      border: none windowtext 1pt;
                      color: #262626;
                      padding: 0cm;
                    "
                    ><span style="text-decoration: none">
                      tarafından okunabilen; kullancının dil, lokasyon tercihi
                      gibi temel site fonksiyonlarının yerine getirilmesi
                      sürecinde
                    </span></span
                  >kullanıcı deneyiminizi geliştirebilmek
                  <span
                    style="
                      border: none windowtext 1pt;
                      color: #262626;
                      padding: 0cm;
                    "
                    ><span style="text-decoration: none"
                      >amacıyla kullanılan çerezlerdir.
                    </span></span
                  >&nbsp;</span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="font-family: Times New Roman, Times, serif"
        ><span style="font-size: 16px"
          ><span style="background: white"
            ><span style="line-height: 150%"
              ><span style="vertical-align: baseline"
                ><span style="color: #262626"
                  >Üçüncü taraf çerezler, Türkiye Halk Bankası A.Ş. Emekli
                  Sandığı Vakfı’nın iş ortakları veya servis sağlayıcılar gibi
                  üçüncü kişilere ait ve üçüncü kişiler tarafından yürütülen
                  çerezlerdir.
                </span></span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="font-size: 16px"
        ><span style="font-family: Times New Roman, Times, serif"
          ><span style="background: white"
            ><span style="line-height: 150%"
              ><span style="vertical-align: baseline"
                ><b
                  ><u
                    ><span style="color: #262626"
                      >Websitemizde Kullanılan Çerezler</span
                    ></u
                  ></b
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="line-height: 150%"
        ><b
          ><u
            ><span style="font-size: 12pt"
              ><span style="line-height: 150%"
                ><span style="font-family: 'Times New Roman', serif"
                  ><span style="color: #262626">Teknik Çerezler:</span></span
                ></span
              ></span
            ></u
          ></b
        ><b> </b
        ><span style="font-size: 12pt"
          ><span style="line-height: 150%"
            ><span style="font-family: 'Times New Roman', serif"
              ><span style="color: #262626"
                >İnternet sitesinin düzgün çalışmasının takibi amacıyla
                kullanılan çerezlerdir.</span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="line-height: 150%"
        ><a name="_Hlk18486101"
          ><b
            ><u
              ><span style="font-size: 12pt"
                ><span style="line-height: 150%"
                  ><span style="font-family: 'Times New Roman', serif"
                    ><span style="color: #262626"
                      >Çerezleri Nasıl Silebilirim veya Reddedebilirim?</span
                    ></span
                  ></span
                ></span
              ></u
            ></b
          ></a
        ></span
      >
    </p>

    <p dir="ltr" style="margin-bottom: 17.3pt; text-align: justify">
      <span style="background: white"
        ><span style="line-height: 150%"
          ><span style="font-size: 12pt"
            ><span style="line-height: 150%"
              ><span style="font-family: 'Times New Roman', serif"
                >www.halkvakif.org<span style="color: #262626">
                  internet sayfasında, sitemizin aktif ve verimli bir şekilde
                  çalışması için birtakım zorunlu çerezler kullanılmaktadır.
                  Gizlilik Tercih Paneli üzerinden bu çerezler devre dışı
                  bırakılamamaktadır. Sitemizde kullanılan zorunlu çerezler için
                  “Gizlilik Tercih Paneli”ni kullanabilirsiniz.</span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="margin-bottom: 17.3pt; text-align: justify">
      <span style="background: white"
        ><span style="line-height: 150%"
          ><span style="font-size: 12pt"
            ><span style="line-height: 150%"
              ><span style="font-family: 'Times New Roman', serif"
                ><span style="color: #262626"
                  >Diğer taraftan,
                  <u
                    >zorunlu çerezlerin dışında kalan tüm çerez grupları
                    kendiliğinden “Devre Dışı Bırakılmış” bir şekilde
                    gelmektedir.</u
                  >
                  Kullanıcılarımız, istekleri doğrultusunda bu çerezleri tekrar
                  aktif hale getirebilir veya aktifleştirmiş olduğu çerezleri
                  daha sonra tekrar devre dışı bırakabilir. Ancak önemle
                  belirtmek gerekir ki kullandığımız çerezlerin devre dışı
                  bırakılması/kalması,
                </span></span
              ></span
            ></span
          ><span style="font-size: 12pt"
            ><span style="line-height: 150%"
              ><span style="font-family: 'Times New Roman', serif"
                >www.halkvakif.org<span style="color: #262626">
                  internet sayfasındaki kullanıcı deneyiminizi etkileyebilir.
                  Zorunlu olmayan çerezlerin aktif hale getirilmemesi durumunda,
                  uygulamayı ve/veya internet sitesini kullanmaya devam
                  edebilirsiniz fakat uygulamanın ve/veya internet sitesinin tüm
                  işlevlerine erişemeyebilirsiniz veya sınırlı bir erişim imkânı
                  ile karşılaşabilirsiniz. Uygulamadan ve/veya internet
                  sitesinden tam anlamıyla faydalanmak için çerezlere izin
                  vermenizi tavsiye ediyoruz.</span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="margin-bottom: 17.3pt; text-align: justify">
      <span style="background: white"
        ><span style="line-height: 150%"
          ><b
            ><u
              ><span style="font-size: 12pt"
                ><span style="line-height: 150%"
                  ><span style="font-family: 'Times New Roman', serif"
                    ><span style="color: #262626"
                      >Çerezlerin “Gizlilik Tercihi Paneli” Dışında
                      Yönetilmesi:</span
                    ></span
                  ></span
                ></span
              ></u
            ></b
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="margin-bottom: 17.3pt; text-align: justify">
      <span style="background: white"
        ><span style="line-height: 150%"
          ><span style="font-size: 12pt"
            ><span style="line-height: 150%"
              ><span style="font-family: 'Times New Roman', serif"
                ><span style="color: #262626"
                  >Sitemizde kullanılan çerezleri en verimli şekilde yönetimi
                  “Gizlilik Tercih Paneli” üzerinden sağlanmaktadır. Ancak siz
                  değerleri kullanıcılarımız, çerezleri yönetmek için
                  tarayıcınıza bağlı olarak aşağıdaki yöntemleri de tercih
                  edebilirsiniz:</span
                ></span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <b
        ><span style="font-size: 12pt"
          ><span style="line-height: 107%"
            ><span style="font-family: 'Times New Roman', serif"
              >Safari:</span
            ></span
          ></span
        ></b
      ><span style="font-size: 12pt"
        ><span style="line-height: 107%"
          ><span style="font-family: 'Times New Roman', serif">
            Telefonunuzun "Ayarlar" bölümünden "safari" sekmesini seçip,
            "Gizlilik ve Güvenlik" Bölümünden tüm çerez yönetiminizi
            sağlayabilirsiniz.</span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">&nbsp;</p>

    <p dir="ltr" style="text-align: justify">
      <b
        ><span style="font-size: 12pt"
          ><span style="line-height: 107%"
            ><span style="font-family: 'Times New Roman', serif"
              >Diğer tarayıcılar:</span
            ></span
          ></span
        ></b
      ><span style="font-size: 12pt"
        ><span style="line-height: 107%"
          ><span style="font-family: 'Times New Roman', serif">
            İlgili tarayıcının yardım veya destek sayfalarını inceleyebilir veya
          </span></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <a href="http://www.youronlinechoices.eu/tr/" target="" title=""
        ><span style="font-size: 12pt"
          ><span style="line-height: 107%"
            ><span style="font-family: 'Times New Roman', serif"
              >www.youronlinechoices.eu/tr/</span
            ></span
          ></span
        ></a
      ><span style="font-size: 12pt"
        ><span style="line-height: 107%"
          ><span style="font-family: 'Times New Roman', serif">
            adresini ziyaret ederek detaylı bilgi alabilirsiniz.
          </span></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">&nbsp;</p>

    <p dir="ltr" style="margin-bottom: 17.3pt; text-align: justify">
      <span style="background: white"
        ><span style="line-height: 150%"
          ><span style="font-size: 12pt"
            ><span style="line-height: 150%"
              ><span style="font-family: 'Times New Roman', serif"
                >Anlık olarak kaç sayıda çerez ile takip edildiğinizi
                görüntülemek için Ghostery uygulamasını veya doğrudan tarayıcıyı
                olarak Brave kullanabilirsiniz.</span
              ></span
            ></span
          ></span
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">
      <span style="line-height: 150%"
        ><b
          ><u
            ><span style="font-size: 12pt"
              ><span style="line-height: 150%"
                ><span style="font-family: 'Times New Roman', serif"
                  ><span style="color: #262626">ÇEREZ TABLOSU:</span></span
                ></span
              ></span
            ></u
          ></b
        ></span
      >
    </p>

    <p dir="ltr" style="text-align: justify">&nbsp;</p>

    <table
      class="Table"
      dir="ltr"
      style="
        width: 507.45pt;
        margin-left: 3.9pt;
        border-collapse: collapse;
        border: none;
      "
      width="0"
    >
      <tbody>
        <tr style="height: 18.55pt">
          <td
            style="
              width: 84.95pt;
              border: solid windowtext 1.5pt;
              border-right: solid windowtext 1pt;
            "
            valign="top"
            width="113"
          >
            <p
              align="center"
              style="text-align: center; padding: 0cm 3.5pt 0cm 3.5pt"
            >
              <br />
              <span style="line-height: 150%"
                ><span style="height: 18.55pt"
                  ><b
                    ><span style="font-size: 12pt"
                      ><span style="line-height: 150%"
                        ><span style="font-family: 'Times New Roman', serif"
                          ><span style="color: #262626">ÇEREZ TÜRÜ</span></span
                        ></span
                      ></span
                    ></b
                  ></span
                ></span
              >
            </p>
          </td>
          <td
            style="
              width: 151.75pt;
              border-top: solid windowtext 1.5pt;
              border-left: none;
              border-bottom: solid windowtext 1.5pt;
              border-right: solid windowtext 1pt;
            "
            valign="top"
            width="203"
          >
            <p
              align="center"
              style="text-align: center; padding: 0cm 3.5pt 0cm 3.5pt"
            >
              <br />
              <span style="line-height: 150%"
                ><span style="height: 18.55pt"
                  ><b
                    ><span style="font-size: 12pt"
                      ><span style="line-height: 150%"
                        ><span style="font-family: 'Times New Roman', serif"
                          ><span style="color: #262626">ÇEREZ İSMİ</span></span
                        ></span
                      ></span
                    ></b
                  ></span
                ></span
              >
            </p>
          </td>
          <td
            style="
              width: 167.05pt;
              border-top: solid windowtext 1.5pt;
              border-left: none;
              border-bottom: solid windowtext 1.5pt;
              border-right: solid windowtext 1pt;
            "
            valign="top"
            width="223"
          >
            <p
              align="center"
              style="text-align: center; padding: 0cm 3.5pt 0cm 3.5pt"
            >
              <br />
              <span style="line-height: 150%"
                ><span style="height: 18.55pt"
                  ><b
                    ><span style="font-size: 12pt"
                      ><span style="line-height: 150%"
                        ><span style="font-family: 'Times New Roman', serif"
                          ><span style="color: #262626"
                            >ÇEREZİN KULLANIM AMACI</span
                          ></span
                        ></span
                      ></span
                    ></b
                  ></span
                ></span
              >
            </p>
          </td>
          <td
            style="
              width: 103.7pt;
              border: solid windowtext 1.5pt;
              border-left: none;
            "
            valign="top"
            width="138"
          >
            <p
              align="center"
              style="text-align: center; padding: 0cm 3.5pt 0cm 3.5pt"
            >
              <span style="line-height: 150%"
                ><span style="height: 18.55pt"
                  ><b
                    ><span style="font-size: 12pt"
                      ><span style="line-height: 150%"
                        ><span style="font-family: 'Times New Roman', serif"
                          ><span style="color: #262626"
                            >BİRİNCİ TARAF/ÜÇÜNCÜ TARAF</span
                          ></span
                        ></span
                      ></span
                    ></b
                  ></span
                ></span
              >
            </p>
          </td>
        </tr>
        <tr style="height: 12.75pt">
          <td
            rowspan="3"
            style="
              width: 84.95pt;
              border-top: none;
              border-left: solid windowtext 1.5pt;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
            "
            valign="top"
            width="113"
          >
            <p
              align="center"
              style="text-align: center; padding: 0cm 3.5pt 0cm 3.5pt"
            >
              <br />
              &nbsp;
            </p>

            <p
              align="center"
              style="text-align: center; padding: 0cm 3.5pt 0cm 3.5pt"
            >
              <span style="line-height: 150%"
                ><span style="height: 12.75pt"
                  ><b
                    ><span style="font-size: 12pt"
                      ><span style="line-height: 150%"
                        ><span style="font-family: 'Times New Roman', serif"
                          ><span style="color: #262626"
                            >Teknik Çerezler</span
                          ></span
                        ></span
                      ></span
                    ></b
                  ></span
                ></span
              >
            </p>
          </td>
          <td
            style="
              width: 151.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1.5pt;
              border-right: solid windowtext 1pt;
            "
            valign="top"
            width="203"
          >
            <p
              align="center"
              style="text-align: center; padding: 0cm 3.5pt 0cm 3.5pt"
            >
              <span style="line-height: 150%"
                ><span style="height: 12.75pt"
                  ><span style="font-size: 12pt"
                    ><span style="line-height: 150%"
                      ><span style="font-family: 'Times New Roman', serif"
                        ><span style="color: #262626">_</span></span
                      ></span
                    ></span
                  ><span style="font-size: 9pt"
                    ><span style="background: white"
                      ><span style="line-height: 150%"
                        ><span style="font-family: 'Segoe UI', sans-serif"
                          ><span style="color: #222222">JSESSIONID</span></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </p>
          </td>
          <td
            rowspan="3"
            style="
              width: 167.05pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
            "
            valign="top"
            width="223"
          >
            <p
              align="center"
              style="text-align: center; padding: 0cm 3.5pt 0cm 3.5pt"
            >
              <br />
              <br />
              <span style="line-height: 150%"
                ><span style="height: 12.75pt"
                  ><span style="font-size: 12pt"
                    ><span style="line-height: 150%"
                      ><span style="font-family: 'Times New Roman', serif"
                        ><span style="color: #262626"
                          >İnternet sitesinin düzgün çalışmasının takibi
                          amacıyla kullanılan çerezlerdir.</span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </p>
          </td>
          <td
            rowspan="3"
            style="
              width: 103.7pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1.5pt;
            "
            valign="top"
            width="138"
          >
            <p
              align="center"
              style="text-align: center; padding: 0cm 3.5pt 0cm 3.5pt"
            >
              &nbsp;
            </p>
          </td>
        </tr>
        <tr style="height: 12.75pt">
          <td
            style="
              width: 151.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1.5pt;
              border-right: solid windowtext 1pt;
            "
            valign="top"
            width="203"
          >
            <p
              align="center"
              style="text-align: center; padding: 0cm 3.5pt 0cm 3.5pt"
            >
              <span style="line-height: 150%"
                ><span style="height: 12.75pt"
                  ><span style="font-size: 12pt"
                    ><span style="line-height: 150%"
                      ><span style="font-family: 'Times New Roman', serif"
                        ><span style="color: #262626">_</span></span
                      ></span
                    ></span
                  ><span style="font-size: 9pt"
                    ><span style="background: white"
                      ><span style="line-height: 150%"
                        ><span style="font-family: 'Segoe UI', sans-serif"
                          ><span style="color: #222222"
                            >NSC_IBMLWBLJGBQQ_IUUQT_WJQ</span
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </p>
          </td>
        </tr>
        <tr style="height: 11.25pt">
          <td
            style="
              width: 151.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1.5pt;
              border-right: solid windowtext 1pt;
            "
            valign="top"
            width="203"
          >
            <p
              align="center"
              style="text-align: center; padding: 0cm 3.5pt 0cm 3.5pt"
            >
              <span style="line-height: 150%"
                ><span style="height: 11.25pt"
                  ><span style="font-size: 12pt"
                    ><span style="line-height: 150%"
                      ><span style="font-family: 'Times New Roman', serif"
                        ><span style="color: #262626">_</span></span
                      ></span
                    ></span
                  ><span style="font-size: 9pt"
                    ><span style="background: white"
                      ><span style="line-height: 150%"
                        ><span style="font-family: 'Segoe UI', sans-serif"
                          ><span style="color: #222222"
                            >NSC_IBMLWBLJG_IUUQT_WJQ</span
                          ></span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              >
            </p>
          </td>
        </tr>
      </tbody>
    </table>

    <p dir="ltr" style="text-align: justify">&nbsp;</p>
  </div>
</div>
