import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() items: any[];
  @Input() active_item: string;
  urlState = false;
  reloadShow = false;
  provizyonIdShow = false;
  provizyonKontrolShow = false;
  provizyonId;
  constructor() {
    this.provizyonId = localStorage.getItem("provizyonId");
  }

  ngOnInit() {
    window.location.pathname == "/dashboard/default"
      ? (this.urlState = false)
      : (this.urlState = true);

    switch (window.location.pathname) {
      case "/Fatura-Provizyon/shy-provizyon":
        this.reloadShow = true;
        this.provizyonIdShow = true;
        break;
      case "/Fatura-Provizyon/vakif-provizyon":
        this.reloadShow = true;
        this.provizyonIdShow = true;
        break;
      case "/dis-tedavi/dis-tedavi-detay":
        this.reloadShow = true;
        this.provizyonIdShow = true;
        break;
      case "/dis-tedavi/dis-tedavi-detay-shy":
        this.reloadShow = true;
        this.provizyonIdShow = true;
        break;
      case "/seansli-islemler/shyseansli-islem-provizyon":
        this.reloadShow = true;
        this.provizyonIdShow = true;
        break;
      case "/seansli-islemler/vkseansli-islem-provizyon":
        this.reloadShow = true;
        this.provizyonIdShow = true;
        break;
      case "/yatarak-tedavi/yatarak-tedavi-vakif-detay":
        this.reloadShow = true;
        this.provizyonIdShow = true;
        break;
      case "/yatarak-tedavi/yatarak-tedavi-shy-detay":
        this.reloadShow = true;
        this.provizyonIdShow = true;
        break;
      case "/optik-provizyon/shy-optik-detay":
        this.reloadShow = true;
        this.provizyonIdShow = true;
        break;
      case "/optik-provizyon/vakif-optik-detay":
        this.reloadShow = true;
        this.provizyonIdShow = true;
        break;
      case "/provizyon-kontrol/provizyon-kontrol-vakif":
        this.provizyonIdShow = true;
        this.provizyonKontrolShow = true;
        break;
      case "/provizyon-kontrol/provizyon-kontrol-shy":
        this.provizyonIdShow = true;
        this.provizyonKontrolShow = true;
        break;
      case "/seansli-islem-talep/vakif-seansli-islem-detay":
        this.reloadShow = true;
        break;
      default:
        break;
    }
    this.provizyonId = localStorage.getItem("provizyonId");
  }

  pageRefresh() {
    location.reload();
  }
}
