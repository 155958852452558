import { Component, OnInit } from "@angular/core";
import { VkfDisEmitService } from "src/app/shared/services/emitService/dis-tedavi/vakif/vkf-dis-emit.service";
import { OptikEmitService } from "src/app/shared/services/emitService/optik/optik-emit.service";
import { SiVakifEmitService } from "src/app/shared/services/emitService/seansli-islemler/vakif/si-vakif-emit.service";
import { YtBaslikEmitService } from "src/app/shared/services/emitService/yatarak-tedavi/yt-baslik/yt-baslik-emit.service";
import { TarihFormatService } from "src/app/shared/services/genel/genel/TarihFormat.service";
import { HttpService } from "src/app/shared/services/http.service";
import { OrtakAyaktaTedaviVkfshyService } from "src/app/shared/services/ortakTanimlar/ortak-ayakta-tedavi-vkfshy.service";
import { OrtakTanimlarService } from "src/app/shared/services/ortakTanimlar/ortak-tanimlar.service";
import { VakifIcinOrtakService } from "src/app/shared/services/vakifIcınOrtak/vakif-icin-ortak.service";

@Component({
  selector: "app-vkf-title-cards",
  templateUrl: "./vkf-title-cards.component.html",
  styleUrls: ["./vkf-title-cards.component.scss"],
})
export class VkfTitleCardsComponent implements OnInit {
  constructor(
    private ortakTanimlar: OrtakTanimlarService,
    private http: HttpService,
    private vioHttp: VakifIcinOrtakService,
    private emitServiceAt: OrtakAyaktaTedaviVkfshyService,
    private emitServiceDis: VkfDisEmitService,
    private emitServiceSi: SiVakifEmitService,
    private emitServiceYt: YtBaslikEmitService,
    private emitServiceOptik: OptikEmitService,
    private tarihFormat: TarihFormatService
  ) {
    this.provizyonId = localStorage.getItem("provizyonId");
    this.islemTipId = localStorage.getItem("islemTipId");
  }
  kdvOran;
  tevkifatOran;
  provizyonGetirData;
  provizyonId;
  islemTipId;
  ngOnInit(): void {
    this.islemTipListesi();
  }

  getirEmit() {
    switch (Number(this.type)) {
      case 1:
        this.emitServiceAt.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
        break;

      case 2:
        this.emitServiceOptik.getOptikEmit.subscribe((res) => {
          this.provizyonGetir();
        });
        break;
      case 3:
        this.emitServiceOptik.getOptikEmit.subscribe((res) => {
          this.provizyonGetir();
        });
        break;
      case 4:
        this.emitServiceDis.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
        break;

      case 9:
        this.emitServiceSi.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });

        break;
      case 7:
        this.emitServiceYt.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
      case 6:
        this.emitServiceYt.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
      case 10:
        this.emitServiceYt.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
      case 11:
        this.emitServiceYt.getEmit.subscribe((res) => {
          this.provizyonGetir();
        });
        break;
      default:
        break;
    }
  }
  type;
  async islemTipListesi() {
    this.vioHttp.islemTipListesi(this.provizyonId).then((res) => {
      this.type = res.data[0].islemTipID;

      this.getirEmit();
    });
  }
  async provizyonGetir() {
    this.vioHttp.provizyonGetir(this.provizyonId).then((response) => {
      this.provizyonGetirData = response?.data;

      this.kapsamGetirKontrol(
        this.provizyonGetirData.hastaModelDto.hastaTuru,
        this.provizyonGetirData.hastaModelDto.personelID,
        this.provizyonGetirData.hastaModelDto.akrabaID,
        this.provizyonGetirData.hizmetTarih
      );
      this.tevkifatKdvOranGetir(this.provizyonGetirData.hizmetTarih);
    });
  }

  kapsamData: any;
  kapsamGetirKontrol(hastaTuru, personelId, akrabaId, hizmetTarihi) {
    let d = new Date(hizmetTarihi);
    let tarih = this.tarihFormat.formatDate(d);

    akrabaId == null
      ? this.http
          .httpGet(
            `HastaVakif/HastaPersonelKapsamBilgisiGetir?PersonelID=${personelId}&HizmetTarih=${tarih}`
          )
          .then((res) => {
            this.kapsamData = res?.data.kapsamBilgisi;
          })
      : this.http
          .httpGet(
            `HastaVakif/HastaAkrabaKapsamBilgisiGetir?PersonelID=${personelId}&AkrabaID=${akrabaId}&HizmetTarih=${tarih}`
          )
          .then((res) => {
            this.kapsamData = res?.data.kapsamBilgisi;
          });
  }

  tevkifatKdvOranGetir(tarih) {
    let d = new Date(tarih);
    let tarih1 = this.tarihFormat.formatDate(d);
    this.ortakTanimlar.tevkifatKdvOraniGetir(tarih1).then((res) => {
      this.kdvOran = res?.data.kdvOran;
      this.tevkifatOran = res?.data.tevkifatOran;
    });
  }
}
