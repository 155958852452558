import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastMessageService } from '../../toastMessage.service';

@Injectable({
  providedIn: 'root'
})


@Pipe({
  name: 'mycurrency',
})
export class GenelService implements PipeTransform {

  constructor(private mesaj: ToastMessageService, private spinner: NgxSpinnerService) { }

  gunAyYilTarih() {

  }
  yilAyGun() {

  }
  tutarFormatTr(tutar: number) {
    const numberFormat2 = new Intl.NumberFormat('tr-TR');

    return numberFormat2.format(tutar);
  }
  spinnerShow() {
    return this.spinner.show()
  }
  mesajKontrol(kontrol: boolean, mesaj: string) {
    this.spinner.hide()
    return kontrol == true ? this.mesaj.successMessage(mesaj, 'Başarılı') : this.mesaj.warningMessage(mesaj, 'Başarısız')

  }

  mesajError() {
    return this.mesaj.errorMessage('Api Hatası Lütfen Yetkiliye Başvurunuz.', 'HATA')
  }

  transform(
    value: number,
    currencyCode: string = 'EUR',
    display:
      | 'code'
      | 'symbol'
      | 'symbol-narrow'
      | string
      | boolean = 'symbol',
    digitsInfo: string = '3.2-2',
    locale: string = 'fr',
  ): string | null {
    return formatCurrency(
      value,
      locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    );
  }
}
