import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppModule } from 'src/app/app.module';

@Component({
  selector: 'app-shyle-sheet',
  templateUrl: './shyle-sheet.component.html',
  styleUrls: ['./shyle-sheet.component.scss']
})
export class ShyleSheetComponent implements OnInit {

  page = 4;
  closeResult: string;

  messageSuccess() {
    this.toast.success("Sorgulanan kişi üyemiz değildir.", "Kayıtlı üye veya yakını bulunamadı.", { disableTimeOut: true, closeButton: true, positionClass: 'toast-bottom-right' });
  }
  messageError() {
    this.toast.error("Sağlık İşlemleri için kişi müstehaktır.", "Sorgulanan üyemiz sağlık kapsamındadır.", { disableTimeOut: true, closeButton: true, positionClass: 'toast-bottom-right' });
  }
  messageWarning() {
    this.toast.warning("Tanı girilmeyen provizyon ödemeye gönderilmez.", "Henüz tanı girmediniz!", { disableTimeOut: true, closeButton: true, positionClass: 'toast-bottom-right' });
  }

  constructor(private toast: ToastrService,) { AppModule }

  ngOnInit(): void {
  }

}
