<div class="w-100">
  <div class="user-info justify-content-between flex-wrap">
    <div class="card w-30">
      <div class="user-alt-info">
        <div class="d-flex justify-content-between">
          <div style="display: flex; align-items: center">
            <ngx-avatar
              class="b-r-10"
              size="42"
              name="{{ provizyonGetirData?.hastaModelDto.hastaAdSoyad }}"
            ></ngx-avatar>
            <div class="ml-2 mt-auto mb-auto">
              <span class="user-title-name" tyle="white-space: nowrap"
                >Hasta Adı Soyadı</span
              ><br />
              <bold
                ><span
                  class=""
                  style="
                    font-size: 1rem;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 20px;
                  "
                  >{{ provizyonGetirData?.hastaModelDto.hastaAdSoyad }}</span
                ></bold
              >
            </div>
          </div>

          <div style="text-align: right">
            <div>
              <div class="" style="display: flex; align-items: center">
                <!-- <div class="tooltip-card d-inline-flex" style="gap: 15px">
                  <span
                    *ngIf="!provizyonGetirData?.hastaModelDto.vipMi"
                    style="white-space: nowrap"
                    class="fas fa-exclamation-circle warning-tooltip-icon"
                    placement="right"
                    ngbTooltip="Katkı payı yararlanıcı (SHY) tarafından ödenecektir!..."
                  ></span>
                </div> -->
                <p class="ml-1" style="white-space: nowrap">Üye Durumu</p>
              </div>
              <div style="margin-top: -12px">
                <span class="vakifli ml-lg-3"><b>VAKIF </b></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="padding: 15px 20px 20px 20px">
        <div class="d-flex justify-content-between">
          <label class="left-text">T.C. Kimlik No</label>
          <span class="right-text">{{
            provizyonGetirData?.hastaModelDto?.hastaTcKimlikNo
          }}</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Doğum Tarihi</label>
          <span class="right-text">{{
            provizyonGetirData?.hastaModelDto?.hastaDogumTarihi
              | date : "dd.MM.yyyy"
          }}</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Hizmet Alan</label>
          <span class="right-text">{{
            provizyonGetirData?.hastaModelDto?.hizmetAlan
          }}</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Üye Statüsü</label>
          <span class="right-text">{{
            provizyonGetirData?.hastaModelDto?.uyeCalismaDurum
          }}</span>
        </div>
      </div>
    </div>

    <div class="card w-30">
      <div
        class="user-alt-info provizyon-bilgileri d-flex justify-content-center"
        *ngIf="true"
      >
        <div class="">
          <h2>Provizyon Bilgileri</h2>
        </div>
      </div>
      <!-- <div class="user-alt-info-orange">
        <div class="d-flex p-2 align-items-center">
          <h2>Katkı payı üye tarafından ödenecektir!</h2>
        </div>
      </div> -->
      <div style="padding: 15px 20px 20px 20px">
        <div class="d-flex justify-content-between">
          <label class="left-text">Provizyon No</label>
          <span class="right-text">{{ provizyonGetirData?.id }}</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Hizmet Tarihi</label>
          <span class="right-text">{{
            provizyonGetirData?.hizmetTarih | date : "dd.MM.yyy"
          }}</span>
        </div>
        <hr
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
          *ngIf="
            provizyonGetirData?.talepTurTanimID != 3 &&
            provizyonGetirData?.talepTurTanimID != 5
          "
        />
        <div
          class="d-flex justify-content-between"
          *ngIf="
            provizyonGetirData?.talepTurTanimID != 3 &&
            provizyonGetirData?.talepTurTanimID != 5
          "
        >
          <label class="left-text">Değerlendirme Durumu</label>
          <span class="right-text">{{
            provizyonGetirData?.provizyonFaturaDurumTanim?.ad
          }}</span>
        </div>
        <hr
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
          *ngIf="provizyonGetirData?.talepTurTanimID == 3"
        />
        <div
          class="d-flex justify-content-between"
          *ngIf="provizyonGetirData?.talepTurTanimID == 3"
        >
          <label class="left-text">Onay Durumu</label>
          <span class="right-text">{{
            provizyonGetirData?.provizyonDisDurum?.ad
          }}</span>
        </div>
        <hr
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
          *ngIf="provizyonGetirData?.talepTurTanimID == 5"
        />
        <div
          class="d-flex justify-content-between"
          *ngIf="provizyonGetirData?.talepTurTanimID == 5"
        >
          <label class="left-text">Değerlendirme Durumu</label>
          <span class="right-text">{{
            provizyonGetirData?.provizyonYatisDurum?.ad
          }}</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Tevkifatlı mı?</label>
          <span class="right-text">
            <div class="checkbox">
              <input
                id="checkbox1"
                type="checkbox"
                disabled
                [checked]="provizyonGetirData?.tevkifatVar"
              />
              <label for="checkbox1"></label>
            </div>
            <!-- {{
            provizyonGetirData?.tevkifatVar == true ? "Evet" : "Hayır"
          }} -->
          </span>
        </div>
      </div>
    </div>

    <div class="card w-30">
      <div style="padding: 15px 20px 20px 20px">
        <div
          class="d-flex justify-content-between"
          *ngIf="
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == true) ||
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == false)
          "
        >
          <label class="left-text">Tutar (KDV'siz)</label>
          <h3 class="tutarlar">
            {{ provizyonGetirData?.toplamTutarKDVsiz | trFormat }}
          </h3>
        </div>
        <hr
          *ngIf="
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == true) ||
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == false)
          "
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
        />
        <div class="d-flex justify-content-between">
          <label class="left-text">Hesaplanan KDV (%{{ kdvOran }})</label>
          <h3 class="tutarlar">
            {{ provizyonGetirData?.toplamKDV | trFormat }}
          </h3>
        </div>
        <hr
          *ngIf="
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == true) ||
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == false)
          "
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
        />
        <div
          *ngIf="
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == true) ||
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == false)
          "
          class="d-flex justify-content-between"
        >
          <label class="left-text">Vergiler Dahil Tutar</label>
          <h3 class="tutarlar">
            {{ provizyonGetirData?.toplamTutarKDVli | trFormat }}
          </h3>
        </div>
        <hr
          *ngIf="kdvOran > 0 && provizyonGetirData?.tevkifatVar == true"
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
        />
        <div
          *ngIf="kdvOran > 0 && provizyonGetirData?.tevkifatVar == true"
          class="d-flex justify-content-between"
        >
          <label class="left-text">Tevkifat Oranı </label>
          <h3 class="tutarlar">{{ tevkifatOran }}/100</h3>
        </div>
        <hr
          *ngIf="kdvOran > 0 && provizyonGetirData?.tevkifatVar == true"
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
        />
        <div
          *ngIf="kdvOran > 0 && provizyonGetirData?.tevkifatVar == true"
          class="d-flex justify-content-between"
        >
          <label class="left-text">Ödenecek Tevkifat Tutarı </label>
          <h3 class="tutarlar">
            {{ provizyonGetirData?.odenecekTevkifatTutar | trFormat }}
          </h3>
        </div>

        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text text-danger">Red Tutar (KDV'li)</label>
          <h3 class="text-danger red-tutar">
            {{ provizyonGetirData?.redTutarKDVli | trFormat }}
          </h3>
        </div>

        <div
          class="d-flex justify-content-between odenecek-tutar-bg mt-auto mb-auto"
          style="margin-left: -8px; margin-right: -8px"
        >
          <h2 class="odenecek-tutar">Ödenecek Tutar</h2>
          <h3 class="odenecek-tutar">
            {{ provizyonGetirData?.tutar | trFormat }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</div>
