<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <app-search></app-search>
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink='/'>
          <img class="img-fluid for-light" src="https://www.halkvakif.org/images/halkvakif_logo.png" alt="">
          <img class="img-fluid for-dark" src="https://saglik.halkvakif.org/Images/HalkLogo.png" alt="">
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
    <div class="left-header col horizontal-wrapper pl-0" hidden>
      <app-mega-menu></app-mega-menu>
    </div>
    <div class="nav-right col-12 pull-right right-header p-0">
      <ul class="nav-menus">
        <!-- <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>
        <li>
          <span class="header-search" (click)="searchToggle()">
            <app-feather-icons [icon]="'search'"></app-feather-icons>
          </span>
        </li>
        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li>
        <li class="onhover-dropdown">
          <app-bookmark></app-bookmark>
        </li> -->
<!--         <li class="mr-5">
          <div class="mode" (click)="layoutToggle()">
            <i class="fa fa-moon-o" *ngIf="!dark"></i>
            <i class="fa fa-lightbulb-o" *ngIf="dark"></i>
          </div>
        </li> -->
        <!-- <li class="cart-nav onhover-dropdown">
          <app-cart></app-cart>
        </li> -->
        <!-- <li class="onhover-dropdown">
          <app-message-box></app-message-box>
        </li> -->
<!--         <li class="maximize" hidden>
          <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
            <app-feather-icons [icon]="'maximize'"></app-feather-icons>
          </a>
        </li> -->
        <li class="profile-nav" >
           <app-my-account class="d-flex align-items-center h-100 justify-content-between"></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->