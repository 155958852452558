import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-style-sheet-dort',
  templateUrl: './style-sheet-dort.component.html',
  styleUrls: ['./style-sheet-dort.component.scss']
})
export class StyleSheetDortComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  avatarName = "resul"
  vipMi = true;
}
