<div id="print-section">
  <div class="provizyon-form">
    <div
      class="provizyon-form__header d-flex justify-content-between align-items-center"
    >
      <div class="provizyon-form__header__logo">
        <img src="../../../assets/images/logo_mavi.svg" />
      </div>
      <div class="provizyon-form__header__title">
        <p>VAKIF ÜYELİĞİ PROVİZYON FORMU</p>
      </div>
    </div>
    <div
      class="provizyon-form__header-bar d-flex justify-content-between align-items-center"
    >
      <div class="provizyon-form__header-bar__title">
        <p>ÖZEL ACIBADEM DR. ŞİNASİ CAN HASTANESİ</p>
      </div>
      <button
        class="provizyon-form__header-bar__print-button"
        [useExistingCss]="true"
        printSectionId="print-section"
        ngxPrint
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>498A4435-42FA-4DDB-98EF-FA237E83C115</title>
          <defs>
            <rect id="path-1" x="0" y="0" width="20" height="20"></rect>
          </defs>
          <g
            id="UI-Kit"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Style-Sheet"
              transform="translate(-229.000000, -5053.000000)"
            >
              <g id="Group-21" transform="translate(144.000000, 5038.000000)">
                <g id="icon/print" transform="translate(83.000000, 13.000000)">
                  <g
                    id="icon/Printer"
                    transform="translate(2.000000, 2.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <use xlink:href="#path-1"></use>
                    </mask>
                    <g id="Canvas"></g>
                    <path
                      d="M2.66666667,5.41666667 L4.5,5.41666667 L4.5,0.833333333 L15.5,0.833333333 L15.5,5.41666667 L17.3333333,5.41666667 C18.3458833,5.41666667 19.1666667,6.2374775 19.1666667,7.25 L19.1666667,14.5833333 C19.1666667,15.5958833 18.3458833,16.4166667 17.3333333,16.4166667 L15.5,16.4166667 L15.5,19.1666667 L4.5,19.1666667 L4.5,16.4166667 L2.66666667,16.4166667 C1.65414417,16.4166667 0.833333333,15.5958833 0.833333333,14.5833333 L0.833333333,7.25 C0.833333333,6.2374775 1.65414417,5.41666667 2.66666667,5.41666667 Z M13.6666667,5.41666667 L6.33333333,5.41666667 L6.33333333,2.66666667 L13.6666667,2.66666667 L13.6666667,5.41666667 Z M17.3333333,7.25 L17.3333333,14.5833333 L15.5,14.5833333 L15.5,12.75 L4.5,12.75 L4.5,14.5833333 L2.66666667,14.5833333 L2.66666667,7.25 L17.3333333,7.25 Z M13.6666667,14.5833333 L6.33333333,14.5833333 L6.33333333,17.3333333 L13.6666667,17.3333333 L13.6666667,14.5833333 Z M16.4166667,9.08333333 C16.4166667,8.57705833 16.006275,8.16666667 15.5,8.16666667 C14.993725,8.16666667 14.5833333,8.57705833 14.5833333,9.08333333 C14.5833333,9.58960833 14.993725,10 15.5,10 C16.006275,10 16.4166667,9.58960833 16.4166667,9.08333333 Z"
                      id="Shape"
                      fill="#FFFFFF"
                      mask="url(#mask-2)"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
    <div class="provizyon-form__content">
      <p class="danger-color">
        Online sağlık uygulaması üzerinden temin edilen vakıf üyeliği provizyon
        formundaki bilgiler ile üyemizin kimlik kontrolü kurumunuzca
        yapılacaktır. Bu süreçteki usulsüzlüklerin sorumluluğu kurumunuza
        aittir.
      </p>
      <p class="provizyon-form__content__title">Provizyon Bilgileri</p>
      <div class="d-flex">
        <table class="table-top w-50 mr-1">
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Üye Sicil No</span
              ><span>:</span><span class="row-text">74410</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Üye Adı Soyadı</span
              ><span>:</span><span class="row-text">Sezen Yavuz</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Hasta Adı Soyadı</span
              ><span>:</span><span class="row-text">Sezen Yavuz</span>
            </td>
          </tr>
        </table>
        <table class="table-top w-50 ml-1">
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Provizyon Tarihi</span
              ><span>:</span><span class="row-text">22.11.2022 - 11:50</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Provizyon Numarası</span
              ><span>:</span><span class="row-text">541761</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title">Yakınlık</span
              ><span>:</span><span class="row-text">Kendisi</span>
            </td>
          </tr>
        </table>
      </div>
      <div class="yazdir-card m-t-5">
        <span><b>Hastanın yakınması:</b></span>
      </div>
      <div class="yazdir-card m-t-5">
        <span><b>Öntanı:</b></span>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae
          ante quam. Sed sed vestibulum sapien, ut pulvinar nulla. Nunc posuere
          accumsan semper.
        </p>
      </div>
      <div class="yazdir-card m-t-5">
        <span><b>İstenen tektikler:</b></span>
      </div>
      <div class="yazdir-card m-t-5">
        <span><b>Planlanan tedaviler:</b></span>
      </div>
      <div class="yazdir-card imza m-t-5">
        <p class="d-flex align-items-center justify-content-center text-center">
          Hastanın …../…../………. tarihinde tedavisi yapılmıştır.<br />Doktor (Adı
          Soyadı/Branşı/İmza)
        </p>
      </div>

      <div class="d-flex m-t-5 yazdir-card__bottom">
        <div class="yazdir-card w-100">
          <p>Vakıf İletişim Bilgileri</p>
          <span
            >Atatürk Mah. Alemdağ Cad. No:25 Kat: 2-3-4<br />
            Ümraniye/İstanbul<br />
            Tel : (0216) 633 70 00 - Fax : (0216) 443 68 39<br />
            emekli.sandigi@halkbank.com.tr</span
          >
        </div>
        <div class="yazdir-card w-100" style="border-left: 0">
          <p>Hasta Yatış Onayı İletişim Bilgileri</p>
          <span
            >Çiğdem Güney - (0216) 633 70 16<br />
            Elif Üzülmez - (0216) 633 70 19<br />
            Ayşenur Kalyoncu - (0216) 633 70 41<br />
            yatisonay@halkbank.com.tr
          </span>
        </div>
        <div class="yazdir-card w-100" style="border-left: 0">
          <p>Ayakta Tedavi Provizyon İletişim Bilgileri</p>
          <span
            >(0216) 633 70 14-21-22-23-31-32-34-42<br />
            ayaktatedaviprovizyon@halkbank.com.tr</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
