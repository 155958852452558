import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/firebase/auth.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  user: any;
  avatarName
  constructor(public authService: AuthService) {
    this.user = JSON.parse(sessionStorage.getItem("user"));

  }

  ngOnInit() {
    let fullName = this.user.data.kullaniciBilgileri.adSoyad
    fullName.split(" ")
    let name = fullName.split(" ")[0][0]
    let surName = fullName.split(" ")[1][0]
    this.avatarName = name + surName

  }

  Logout() {
    this.authService.Logout();
  }
}
