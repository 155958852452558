import { BrowserModule } from "@angular/platform-browser";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
  APP_INITIALIZER,
} from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";

// for HttpClient import:
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
// for Router import:
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
// for Core import:
import { LoadingBarModule } from "@ngx-loading-bar/core";

import { AuthService } from "./shared/services/firebase/auth.service";
import { AdminGuard } from "./shared/guard/admin.guard";
import { SecureInnerPagesGuard } from "./shared/guard/SecureInnerPagesGuard.guard";
import { CookieService } from "ngx-cookie-service";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import { AvatarModule } from "ngx-avatar";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
import { NgxPrintModule } from "ngx-print";
import { ShyleSheetComponent } from "./components/shylesheet/shyle-sheet/shyle-sheet.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { StyleSheetIkiComponent } from "./components/shylesheet/style-sheet-2/style-sheet-iki/style-sheet-iki.component";
import { InvoiceProvisionModule } from "./components/invoice-provision/invoice-provision.module";
import { MatNativeDateModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import {
  DropzoneConfigInterface,
  DropzoneModule,
  DROPZONE_CONFIG,
} from "ngx-dropzone-wrapper";
import { MatSelectModule } from "@angular/material/select";
import { StyleSheetUcComponent } from "./components/shylesheet/style-sheet-uc/style-sheet-uc.component";
import { SifreIslemleriComponent } from "./auth/login-islemleri/sifre-islemleri/sifre-islemleri.component";

import { HttpService } from "./shared/services/http.service";
import { Print1Component } from "./print-global/print1/print1.component";
import { Print2Component } from "./print-global/print2/print2.component";
import { Print3Component } from "./print-global/print3/print3.component";
import { Print4Component } from "./print-global/print4/print4.component";
import { Print5Component } from "./print-global/print5/print5.component";
import { Print6Component } from "./print-global/print6/print6.component";
import { HttpErrorInterceptorService } from "./shared/services/http-error-interceptor.service";
import { MatIcon, MatIconModule } from "@angular/material/icon";
import { AppInitService } from "../app/shared/services/app-init.service";
import { CookieComponent } from "./cookie/cookie.component";
import { StyleSheetDortComponent } from "./components/shylesheet/style-sheet-dort/style-sheet-dort.component";
import { VersionComponent } from './version/version/version.component';

// const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
//   // Change this to your upload POST address:
//   url: null,
//   acceptedFiles: 'image/*',
//   createImageThumbnails: true
// };

export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.Init();
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ShyleSheetComponent,
    StyleSheetIkiComponent,
    StyleSheetUcComponent,
    SifreIslemleriComponent,
    Print1Component,
    Print2Component,
    Print3Component,
    Print4Component,
    Print5Component,
    Print6Component,
    CookieComponent,
    StyleSheetDortComponent,
    VersionComponent,
  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    FormsModule,
    AvatarModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    NgxPrintModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    NgbModule,
    DropzoneModule,
    MatSelectModule,
    MatNativeDateModule,
    MatInputModule,
    MatIconModule,
  ],
  exports: [],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp1,
      deps: [AppInitService],
      multi: true,
    },
    AuthService,
    AdminGuard,
    SecureInnerPagesGuard,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true,
    },
    // [HttpService, { provide: HTTP_INTERCEPTORS, useClass: HttpSpinnerInterceptorService, multi: true }]
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
