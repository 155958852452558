import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, Validators, FormGroup, NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { CaptchaEmitService } from "src/app/shared/services/emitService/captcha-emit/captcha-emit.service";
import { GenelService } from "src/app/shared/services/genel/genel/genel.service";
import { HttpService } from "src/app/shared/services/http.service";
import { AuthService } from "../../shared/services/firebase/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  animations: [
    trigger("flipState", [
      state(
        "active",
        style({
          transform: "rotateY(179deg)",
        })
      ),
      state(
        "inactive",
        style({
          transform: "rotateY(0)",
        })
      ),
      transition("active => inactive", animate("500ms ease-out")),
      transition("inactive => active", animate("500ms ease-in")),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  user;
  capcthaGoster = false;
  count;
  captchaText;
  loginType = 0;
  loginState = undefined;
  constructor(
    private genel: GenelService,
    private cookieService: CookieService,
    private captchaEmit: CaptchaEmitService,
    private http: HttpService,
    public authService: AuthService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.token = undefined;
    // this.count = this.cookieService.get("count")
  }
  @Input() myEvent: EventEmitter<any>;

  ngOnInit() {
    this.captcha();
    this.valitadion();
    this.emit();
  }

  valitadion() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", Validators.required],
      recaptcha: [null, Validators.required],
    });
  }

  emitCode() {
    this.captchaEmit.getCodeEmit.subscribe((res) => {
      this.code = res;
    });
  }
  emit() {
    this.authService.getEmit.subscribe((res) => {
      this.loginState = res?.type;
      this.user = res?.user;

      switch (Number(this.loginState)) {
        case 1:
          this.loginType = 3;
          break;
        case 2:
          this.loginType = 4;
          break;
        // case 0:

        //   this.loginForm = this.fb.group({
        //     email: ['', [Validators.required]],
        //     password: ['', Validators.required],
        //     recaptcha: [null, Validators.required]
        //   });

        //   this.capcthaGoster = true

        //   break;
        default:
          break;
      }

      // if (this.count >= 3) {
      //   this.loginForm = this.fb.group({
      //     email: ['', [Validators.required]],
      //     password: ['', Validators.required],
      //     recaptcha: [null, Validators.required]
      //   });

      //   this.capcthaGoster = true
      // }
    });
  }

  siffremiUnuttum() {
    this.loginState = 1;
    this.loginType = 1;
  }
  code;
  token: string | undefined;
  showPassword() {
    this.show = !this.show;
  }
  siteKey = "6LdVNDUjAAAAAPWjCUV8elEjY5CYBIAq1Stq9Jiq";
  sifreIslemleri() {
    this.router.navigateByUrl("");
  }
  captchaData: any;
  key: any;

  async captcha() {
    this.http.httpGet("Auth/CaptchaUretici").then((res) => {
      this.captchaData = "data:image/png;base64," + res?.data.deger;
      this.key = res?.data.key;
    });
  }
  async login() {
    this.authService
      .SignInGet(
        this.loginForm.value["email"],
        this.loginForm.value["password"],
        this.key,
        this.loginForm.value["recaptcha"]
      )
      .then((res) => {
        this.captcha();
        this.captchaText = null;
      })
      .catch((err) => {
        this.captcha();
        this.captchaText = null;
      });
  }
  back() {
    this.loginType = 0;
    this.loginState = undefined;
  }
}
