import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class VersionService {
  constructor(private http: HttpService) {}

  async getVersionApi() {
    return this.http.httpGet("Test/ApiVersiyonGetir");
  }
}
