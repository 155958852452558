import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class ToastMessageService {
  constructor(public toster: ToastrService) {}

  successMessage(message: string, title = "") {
    return this.toster.success(message, title, {
      timeOut: 10000,
      // positionClass: "toast-bottom-right",
    });
  }

  errorMessage(message: string, title = "") {
    return this.toster.error(message, title, {
      timeOut: 10000,
      // positionClass: "toast-bottom-right",
    });
  }

  warningMessage(message: string, title = "") {
    return this.toster.warning(message, title, {
      timeOut: 10000,
      // positionClass: "toast-bottom-right",
    });
  }
  infoMessage(message: string, title = "") {
    return this.toster.info(message, title, {
      timeOut: 10000,
      // positionClass: "toast-bottom-right",
    });
  }
}
