import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TarihFormatService {

constructor() { }

formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  return [year, month, day].join('-');
}

}
