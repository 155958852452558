import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class OrtakAyaktaTedaviVkfshyService {

  constructor(private http: HttpService) { }


  allProvizyonListGetir(provizyonId, shyVakifKontrol) {
    switch (Number(shyVakifKontrol)) {
      case 1:

        break;

      case 2:
        this.provizyonGetir(provizyonId)
        break;
    }

  }

  provizyonGetir(provizyonId) {
    this.http.get(`ShyIcinOrtak/ProvizyonGetir/${provizyonId}`).subscribe(res => {
      if (res.basariliMi == true) {
        let islemTipId = res.data.id
        this.islemTipListesi(islemTipId)
      }
    })
  }

  islemTipListesi(islemTipId) {
    return this.http.get(`ShyIcinOrtak/IslemTipListesi/${islemTipId}`)
  }

  icdTanimListesiGetir(islemTipId) {
    return this.http.get(`ShyIcinOrtak/IslemTipListesi/${islemTipId}`)
  }

  private setEmit = new BehaviorSubject<any>(undefined);
  public getEmit: Observable<any> = this.setEmit

  setEmitServis(a) {
    this.setEmit.next(a);
  }

  private setEmit1 = new BehaviorSubject<any>(undefined);
  public getEmit1: Observable<any> = this.setEmit1.asObservable();

  setEmitServis1(a) {
    this.setEmit1.next(a);
  }

  private setEmit2 = new BehaviorSubject<any>(undefined);
  public getEmit2: Observable<any> = this.setEmit2.asObservable();
  taniHizmetTetikle(a) {
    this.setEmit2.next(a);
  }



}
