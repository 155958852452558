import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class ShyIcinOrtakService {

  constructor(private http: HttpService) { }



  provizyonKaydet({ ...data }) {
    return this.http.httpPost('ShyIcinOrtak/ProvizyonKaydet', data)
  }
  provizyonKaydet2({ ...data }) {
    return this.http.post('ShyIcinOrtak/ProvizyonKaydet', data)
  }

  provizyonGetir(id) {
    return this.http.httpGet(`ShyIcinOrtak/ProvizyonGetir/${id}`)
  }
  provizyonGetir2(id) {
    return this.http.get(`ShyIcinOrtak/ProvizyonGetir/${id}`)
  }
  provizyonSil(id) {
    return this.http.httpDelete(`ShyIcinOrtak/ProvizyonSil/${id}`)
  }


  provizyonKurumAciklamaGuncelleme({ ...data }) {
    return this.http.httpPut(`ShyIcinOrtak/ProvizyonKurumAciklamaGuncelle`, data)
  }
  provizyonKurumAciklamaGuncelleme2({ ...data }) {
    return this.http.put(`ShyIcinOrtak/ProvizyonKurumAciklamaGuncelle`, data)
  }

  islemTipKaydet({ ...data }) {
    return this.http.httpPost('ShyIcinOrtak/IslemTipKaydet', data)
  }
  islemTipGuncelle({ ...data }) {
    return this.http.httpPut('ShyIcinOrtak/IslemTipGuncelle', data)
  }

  islemTipGetir(faturaId, Id) {
    return this.http.httpGet(`ShyIcinOrtak/IslemTipGetir?ProvizyonFaturaID=${faturaId}&ID=${Id}`)
  }
  islemTipGetir2(faturaId, Id) {
    return this.http.get(`ShyIcinOrtak/IslemTipGetir?ProvizyonFaturaID=${faturaId}&ID=${Id}`)
  }

  islemTipListesi(Id) {
    return this.http.httpGet(`ShyIcinOrtak/IslemTipListesi/${Id}`)
  }
  islemTipListesi2(Id) {
    return this.http.get(`ShyIcinOrtak/IslemTipListesi/${Id}`)
  }
  islemTipListesi3(Id) {
    return this.http.get(`ShyIcinOrtak/IslemTipListesi/${Id}`)
  }

  islemTipSil(faturaId, Id) {
    return this.http.httpDelete(`ShyIcinOrtak/IslemTipSil?ProvizyonFaturaID=${faturaId}&ID=${Id}`)
  }
  islemTipSil2(faturaId, Id) {
    return this.http.get(`ShyIcinOrtak/IslemTipSil?ProvizyonFaturaID=${faturaId}&ID=${Id}`)
  }

  provizyonFaturaIslemTipKontrol(faturaId) {
    return this.http.httpGet(`ShyIcinOrtak/ProvizyonFaturaIslemTipKontrol/${faturaId}`)
  }
  provizyonFaturaIslemTipKontrol2(faturaId) {
    return this.http.get(`ShyIcinOrtak/ProvizyonFaturaIslemTipKontrol/${faturaId}`)
  }

  islemTipIcd10Kaydet(data) {
    return this.http.httpPost('ShyIcinOrtak/IslemTipIcd10Kaydet', data)
  }
  islemTipIcd10Kaydet2(data) {
    return this.http.post('ShyIcinOrtak/IslemTipIcd10Kaydet', data)
  }

  islemTipIcd10Sil(islemTipId, Id) {
    return this.http.httpDelete(`ShyIcinOrtak/IslemTipIcd10Sil?ProvizyonFaturaIslemTipID=${islemTipId}&ID=${Id}`)
  }


  islemTipIcd10Listesi(islemTipId) {
    return this.http.httpGet(`ShyIcinOrtak/IslemTipIcd10Listesi/${islemTipId}`)
  }

  islemTipIcd10Listesi2(islemTipId) {
    return this.http.get(`ShyIcinOrtak/IslemTipIcd10Listesi/${islemTipId}`)
  }
}
