<!-- Page Sidebar Start-->
<div class="logo-wrapper">
  <a routerLink="/">
    <img
      class="img-fluid for-light"
      width="129"
      src="../../../../assets/images/login/login-logo.svg"
      alt=""
    />
    <p class="dashboard-logo-title">Emekli Sandığı Vakfı</p>
    <img
      class="img-fluid for-dark"
      height="50"
      style="width: 80%"
      src="../../../../assets/images/login/login-logo.svg"
      alt=""
    />
  </a>
  <!--   <div class="back-btn" (click)="sidebarToggle()">
    <i class="fa fa-angle-left"></i>
  </div>
  <div class="toggle-sidebar" (click)="sidebarToggle()">
    <app-feather-icons [icon]="'grid'" class="status_toggle middle sidebar-toggle"></app-feather-icons>
  </div> -->
</div>
<div class="logo-icon-wrapper" style="padding-left: 5px; padding-right: 5px">
  <a href="javascript:void(0)">
    <img
      class="img-fluid"
      height="90"
      width="50"
      style="zoom: 1.6; padding-right: 0px"
      alt=""
    />
  </a>
</div>
<nav class="sidebar-main">
  <div
    class="left-arrow"
    id="left-arrow"
    [class.d-none]="leftArrowNone"
    (click)="scrollToLeft()"
  >
    <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
  </div>
  <div
    id="sidebar-menu"
    [ngStyle]="{
      marginLeft:
        this.layout.config.settings.layout == 'Rome' ||
        'Singapore' ||
        'Barcelona'
          ? margin + 'px'
          : '0px'
    }"
  >
    <ul
      class="sidebar-links custom-scrollbar"
      style="box-shadow: none !important"
    >
      <li class="back-btn">
        <a href="javascript:void(0)">
          <img
            class="img-fluid"
            src="assets/images/logo/logo-icon.png"
            alt=""
          />
        </a>
        <div class="mobile-back text-right" (click)="sidebarToggle()">
          <span>Back</span>
          <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
        </div>
      </li>

      <li
        [class]="menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list'"
        *ngFor="let menuItem of menuItems"
        [ngClass]="{ active: menuItem.active }"
      >
        <div *ngIf="!menuItem.gorunsunMu">
          <div *ngIf="menuItem.headTitle1">
            <h6 class="lan-1">{{ menuItem.headTitle1 | translate }}</h6>
            <p class="lan-2">{{ menuItem.headTitle2 | translate }}</p>
          </div>

          <label
            class="badge badge-{{ menuItem.badgeType }}"
            *ngIf="menuItem.badgeType && menuItem.badgeValue"
            >{{ menuItem.badgeValue }}</label
          >
          <!-- Sub -->
          <a
            href="javascript:void(0)"
            class="sidebar-link sidebar-title"
            [class.link-nav]="!menuItem.children"
            [ngClass]="{ active: menuItem.active }"
            *ngIf="menuItem.type === 'sub'"
            (click)="toggletNavActive(menuItem)"
          >
            <i [class]="menuItem.icon"></i
            ><span>{{ menuItem.title | translate }}</span>
            <div class="according-menu">
              <i
                class="fa fa-angle-{{
                  menuItem.active ? 'down' : 'right'
                }} pull-right"
                *ngIf="menuItem.children"
              ></i>
            </div>
          </a>
          <!-- Link -->
          <a
            [routerLink]="!menuItem.type ? null : [menuItem.path]"
            routerLinkActive="active"
            class="sidebar-link sidebar-title"
            [class.link-nav]="!menuItem.children"
            [ngClass]="{ active: menuItem.active }"
            *ngIf="menuItem.type === 'link'"
          >
            <i [class]="menuItem.icon"></i
            ><span>{{ menuItem.title | translate }}</span>
            <div class="according-menu"></div>
          </a>
          <!-- External Link -->
          <a
            href="{{ !menuItem.type ? null : menuItem.path }}"
            class="sidebar-link sidebar-title"
            [class.link-nav]="!menuItem.children"
            [ngClass]="{ active: menuItem.active }"
            *ngIf="menuItem.type === 'extLink'"
          >
            <app-feather-icons [icon]="menuItem.icon"></app-feather-icons
            ><span>{{ menuItem.title | translate }}</span>
            <div class="according-menu">
              <i
                class="fa fa-angle-{{
                  menuItem.active ? 'down' : 'right'
                }} pull-right"
                *ngIf="menuItem.children"
              ></i>
            </div>
          </a>
          <!-- External Tab Link -->
          <a
            href="{{ !menuItem.type ? null : menuItem.path }}"
            target="_blank"
            class="sidebar-link sidebar-title"
            [class.link-nav]="!menuItem.children"
            [ngClass]="{ active: menuItem.active }"
            *ngIf="menuItem.type === 'extTabLink'"
          >
            <app-feather-icons [icon]="menuItem.icon"></app-feather-icons
            ><span>{{ menuItem.title }}</span>
            <div class="according-menu">
              <i
                class="fa fa-angle-{{
                  menuItem.active ? 'down' : 'right'
                }} pull-right"
                *ngIf="menuItem.children"
              ></i>
            </div>
          </a>
          <!-- 2nd Level Menu -->
          <ul
            class="sidebar-submenu"
            [ngClass]="{ active: menuItem.active }"
            [ngClass]="{
              'menu-open': menuItem.active,
              'menu-close': !menuItem.active
            }"
            *ngIf="menuItem.children"
            [style.display]="menuItem.active ? 'block' : 'none'"
          >
            <li
              *ngFor="let childrenItem of menuItem.children"
              [ngClass]="{ active: childrenItem.active }"
            >
              <!-- Sub -->
              <a
                class="submenu-title"
                href="javascript:void(0)"
                *ngIf="childrenItem.type === 'sub'"
                (click)="toggletNavActive(childrenItem)"
              >
                <span> {{ childrenItem.title | translate }}</span>
                <div class="according-menu">
                  <i
                    class="fa fa-angle-{{
                      childrenItem.active ? 'down' : 'right'
                    }} pull-right"
                    *ngIf="childrenItem.children"
                  ></i>
                </div>
              </a>
              <!-- Link -->
              <a
                class="submenu-title"
                [routerLink]="!childrenItem.type ? null : [childrenItem.path]"
                *ngIf="childrenItem.type === 'link'"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span>{{ childrenItem.title | translate }}</span>
                <i
                  class="fa fa-angle-down pull-right"
                  *ngIf="childrenItem.children"
                ></i>
              </a>
              <!-- External Link -->
              <a
                class="submenu-title"
                href="{{ !childrenItem.type ? null : childrenItem.path }}"
                *ngIf="childrenItem.type === 'extLink'"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <span>{{ childrenItem.title | translate }}</span>
                <i
                  class="fa fa-angle-down pull-right"
                  *ngIf="childrenItem.children"
                ></i>
              </a>
              <!-- External Tab Link -->
              <a
                class="submenu-title"
                href="{{ !childrenItem.type ? null : childrenItem.path }}"
                target="_blank"
                *ngIf="childrenItem.type === 'extTabLink'"
              >
                <span>{{ childrenItem.title | translate }}</span>
                <i
                  class="fa fa-angle-down pull-right"
                  *ngIf="childrenItem.children"
                ></i>
              </a>
              <!-- 3rd Level Menu -->
              <ul
                class="nav-sub-childmenu submenu-content"
                *ngIf="childrenItem.children"
                [ngClass]="{ active: childrenItem.active }"
                [ngClass]="{
                  'menu-open': childrenItem.active,
                  'menu-close': !childrenItem.active
                }"
                [style.display]="childrenItem.active ? 'block' : 'none'"
              >
                <li
                  *ngFor="let childrenSubItem of childrenItem.children"
                  [ngClass]="{ active: childrenSubItem.active }"
                >
                  <!-- Link -->
                  <a
                    [routerLink]="
                      !childrenSubItem.type ? null : [childrenSubItem.path]
                    "
                    *ngIf="childrenSubItem.type === 'link'"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <span> {{ childrenSubItem.title | translate }}</span>
                  </a>
                  <!-- External Link -->
                  <a
                    href="{{
                      !childrenSubItem.type ? null : childrenSubItem.path
                    }}"
                    *ngIf="childrenSubItem.type === 'extLink'"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <span>{{ childrenSubItem.title | translate }}</span>
                  </a>
                  <!-- External Tab Link -->
                  <a
                    href="{{
                      !childrenSubItem.type ? null : childrenSubItem.path
                    }}"
                    target="_blank"
                    *ngIf="childrenSubItem.type === 'extTabLink'"
                  >
                    <span>{{ childrenSubItem.title | translate }}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
  <div
    class="right-arrow"
    id="right-arrow"
    [class.d-none]="rightArrowNone"
    (click)="scrollToRight()"
  >
    <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
  </div>
</nav>
