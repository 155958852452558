<h1 class="kurum-title m-0">{{user.data.kullaniciBilgileri.kurumAd}}</h1>
<div class="media-wrapper d-flex align-items-center">
<div class="media profile-media d-flex align-items-center">
  <img class="b-r-10" src="assets/images/dashboard/profile.jpg" alt="" hidden>
  <ngx-avatar class="b-r-10" size="42" name="{{user.data.kullaniciBilgileri.adSoyad}}"></ngx-avatar>
  <div class="media-body">
    <h4 class="mb-0">{{user.data.kullaniciBilgileri.adSoyad}}</h4>
  </div>
</div>
<div style="display: flex;justify-content: end;">
  <ul class="profile-dropdown onhover-show-div">
    <li><a ><app-feather-icons [icon]="'user'"></app-feather-icons><span>Hesap </span></a></li>
    <li hidden><a href="#"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li>
    <li hidden><a href="#"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li>
    <li hidden><a href="#"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>Settings</span></a></li>
    <li><a (click)="Logout()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>Çıkış Yap</span></a></li>
  </ul>
</div>
<div class="media-icon mx-3"><a href="#"><span class="far fa-light fa-bell"></span></a></div>
<div class="media-icon"><a (click)="Logout()"><span class="far fa-sign-out"></span></a></div>
</div>