<div class="container-fluid">
  <div class="page-title col-12">
    <div class="d-flex justify-content-between col-12">
      <div class="d-flex align-items-center text-center">
        <div
          class="mr-3"
          style="cursor: pointer"
          onclick="history.back();"
          *ngIf="urlState"
        >
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>C2BB1EE7-21A3-4EF8-8E5D-4FAA617181FB</title>
            <defs>
              <rect id="path-1" x="0" y="0" width="24" height="24"></rect>
            </defs>
            <g
              id="Optik"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="3.2.1.Islem-1"
                transform="translate(-332.000000, -104.000000)"
              >
                <g
                  id="icon/Arrow/Chevron_Right"
                  transform="translate(332.000000, 104.000000)"
                >
                  <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                  </mask>
                  <g id="Canvas"></g>
                  <path
                    d="M17.0077983,12.0818343 L8.0078004,3.0818343 L6.40348629,4.80451747 L13.5916794,12 L6.40348629,19.3454633 L8.00779593,21.0818343 L17.0077983,12.0818343 Z"
                    id="Chevron_Right"
                    fill="#004092"
                    fill-rule="nonzero"
                    mask="url(#mask-2)"
                    transform="translate(11.705642, 12.081834) scale(-1, 1) translate(-11.705642, -12.081834) "
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <h1 class="mt-1" *ngIf="!provizyonIdShow">{{ title }}</h1>
        <h1 class="mt-1" *ngIf="provizyonIdShow">
          {{ provizyonId }} <span *ngIf="!provizyonKontrolShow">Düzenleme</span>
          <span *ngIf="provizyonKontrolShow">Provizyon Kontrol Bilgileri</span>
        </h1>
      </div>
      <div>
        <button
          class="btn btn-outline-info d-flex align-items-center res-fw-600"
          *ngIf="reloadShow"
          (click)="pageRefresh()"
        >
          <i class="far fa-redo-alt" style="font-weight: 600"></i
          ><span class="ml-2">Yenile</span>
        </button>
      </div>
      <!-- <div class="col-6" hidden>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard/default'">
              <app-feather-icons [icon]="'home'"></app-feather-icons>
            </a>
          </li>
          <li class="breadcrumb-item" *ngFor="let item of items">{{ item }}</li>
          <li class="breadcrumb-item active">{{ active_item }}</li>
        </ol>
      </div> -->
    </div>
  </div>
</div>

<!-- <app-timer-control></app-timer-control> -->
