<h1>Üst Bilgi Cardları</h1>
<div class="w-100">
  <div class="user-info justify-content-between flex-wrap">
    <div class="card w-30">
      <div class="user-alt-info">
        <div class="d-flex justify-content-between p-2">
          <div class="d-flex">
            <ngx-avatar
              class="b-r-10"
              size="42"
              name="{{ avatarName }}"
            ></ngx-avatar>
            <div class="ml-2 mt-auto mb-auto">
              <span class="user-title-name">Hasta Adı Soyadı</span><br />
              <span class="card-heading">Resul ERTÜRK</span>
            </div>
          </div>

          <div>
            <div>
              <div class="d-flex mt-auto mb-auto">
                <div class="tooltip-card d-inline-flex" style="gap: 15px">
                  <span
                    style="white-space: nowrap"
                    class="fas fa-exclamation-circle warning-tooltip-icon"
                    placement="right"
                    ngbTooltip="Katkı payı yararlanıcı (SHY) tarafından ödenecektir!..."
                  ></span>
                </div>
                <p class="ml-1">Üye Durumu</p>
              </div>
              <div style="margin-top: -12px">
                <span class="shy-sg-kli"><b>SHY (SGK'lı)</b></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="padding: 30px">
        <div class="d-flex justify-content-between">
          <label class="left-text">T.C. Kimlik No</label>
          <span class="right-text">1234432131</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Doğum Tarihi</label>
          <span class="right-text">26.01.1985</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Hizmet Alan</label>
          <span class="right-text">Kendisi</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Üye Statüsü</label>
          <span class="right-text">Çalışan</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">SGK Durumu</label>
          <span class="right-text">Anlaşmalı</span>
        </div>
      </div>
    </div>

    <div class="card w-30">
      <div class="user-alt-info" *ngIf="false">
        <div class="d-flex justify-content-center p-2 text-center">
          <h2>Provizyon Bilgileri</h2>
        </div>
      </div>
      <div class="user-alt-info-vip" *ngIf="vipMi">
        <div class="d-flex p-2 align-items-center">
          <h2>
            <b>Üst düzey yöneticidir. Lütfen özen gösteriniz.</b><br />
            Sorun olması durumunda 0532 275 96 91 numarasını arayınız.
          </h2>
        </div>
      </div>
      <div class="user-alt-info-orange" *ngIf="false">
        <div class="d-flex justify-content-center p-2 align-items-center">
          <h2>Katkı payı üye tarafından ödenecektir!</h2>
        </div>
      </div>
      <div style="padding: 30px">
        <div class="d-flex justify-content-between">
          <label class="left-text">Provizyon No</label>
          <span class="right-text">1234432131</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Provizyon/Hizmet Zamanı</label>
          <span class="right-text">26.01.1985</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Değerlendirme Durumu</label>
          <span class="right-text">Kendisi</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Tevkifatlı mı?</label>
          <span class="right-text">Çalışan</span>
        </div>
      </div>
    </div>

    <div class="card w-30">
      <div style="padding: 30px">
        <div class="d-flex justify-content-between">
          <label class="left-text">SGK Tutarı</label>
          <h3 class="tutarlar">0,00</h3>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text" style="color: #ed6b06; font-size: 18px"
            ><b>Katkı Payı Tutarı</b></label
          >
          <h3 class="tutarlar" style="color: #ed6b06">0,00</h3>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Tutar (KDV'siz)</label>
          <h3 class="tutarlar">0,00</h3>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Hesaplanan KDV (%8)</label>
          <h3 class="tutarlar">0,00</h3>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Vergiler Dahil Tutar</label>
          <h3 class="tutarlar">0,00</h3>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Tevkifat Tutarı (%20)</label>
          <h3 class="tutarlar">0,00</h3>
        </div>

        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text text-danger">Red Tutar (KDV'li)</label>
          <h3 class="text-danger red-tutar">0,00</h3>
        </div>

        <div
          class="d-flex justify-content-between odenecek-tutar-bg mt-auto mb-auto"
          style="margin-left: -8px; margin-right: -8px"
        >
          <h2 class="odenecek-tutar">Ödenecek Tutar</h2>
          <h3 class="odenecek-tutar">0,00</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<h1>Paket Provizyon Listesi</h1>
<div class="d-flex">
  <select style="border-radius: 0; width: 9%">
    <option>Paket No</option>
    <option>Provizyon No</option>
  </select>
  <div class="w-25">
    <div class="form-group m-b-15" style="border-radius: 0">
      <div class="text-icon">
        <input type="text" placeholder="Giriş Yapınız" class="form-control" />
        <span class="far fa-search"></span>
      </div>
    </div>
  </div>
  <div class="ml-3">
    <button class="btn btn-success"><span>Ara</span></button>
  </div>
</div>
