<div id="print-section">
  <div class="provizyon-form">
    <div
      class="provizyon-form__header d-flex justify-content-between align-items-center"
    >
      <div class="provizyon-form__header__logo">
        <img src="../../../assets/images/logo_mavi.svg" />
      </div>
      <div class="provizyon-form__header__title">
        <p>PAKET İCMAL FORMU</p>
        <span>TEVKİFATSIZ</span>
      </div>
      <div class="provizyon-form__header__barkod">
        <img src="../../../assets/images/barkod.png" />
      </div>
    </div>
    <div
      class="provizyon-form__header-bar d-flex justify-content-between align-items-center"
    >
      <div class="provizyon-form__header-bar__title">
        <p>ÖZEL ACIBADEM DR. ŞİNASİ CAN HASTANESİ</p>
      </div>
      <button
        class="provizyon-form__header-bar__print-button"
        [useExistingCss]="true"
        printSectionId="print-section"
        ngxPrint
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>498A4435-42FA-4DDB-98EF-FA237E83C115</title>
          <defs>
            <rect id="path-1" x="0" y="0" width="20" height="20"></rect>
          </defs>
          <g
            id="UI-Kit"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Style-Sheet"
              transform="translate(-229.000000, -5053.000000)"
            >
              <g id="Group-21" transform="translate(144.000000, 5038.000000)">
                <g id="icon/print" transform="translate(83.000000, 13.000000)">
                  <g
                    id="icon/Printer"
                    transform="translate(2.000000, 2.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <use xlink:href="#path-1"></use>
                    </mask>
                    <g id="Canvas"></g>
                    <path
                      d="M2.66666667,5.41666667 L4.5,5.41666667 L4.5,0.833333333 L15.5,0.833333333 L15.5,5.41666667 L17.3333333,5.41666667 C18.3458833,5.41666667 19.1666667,6.2374775 19.1666667,7.25 L19.1666667,14.5833333 C19.1666667,15.5958833 18.3458833,16.4166667 17.3333333,16.4166667 L15.5,16.4166667 L15.5,19.1666667 L4.5,19.1666667 L4.5,16.4166667 L2.66666667,16.4166667 C1.65414417,16.4166667 0.833333333,15.5958833 0.833333333,14.5833333 L0.833333333,7.25 C0.833333333,6.2374775 1.65414417,5.41666667 2.66666667,5.41666667 Z M13.6666667,5.41666667 L6.33333333,5.41666667 L6.33333333,2.66666667 L13.6666667,2.66666667 L13.6666667,5.41666667 Z M17.3333333,7.25 L17.3333333,14.5833333 L15.5,14.5833333 L15.5,12.75 L4.5,12.75 L4.5,14.5833333 L2.66666667,14.5833333 L2.66666667,7.25 L17.3333333,7.25 Z M13.6666667,14.5833333 L6.33333333,14.5833333 L6.33333333,17.3333333 L13.6666667,17.3333333 L13.6666667,14.5833333 Z M16.4166667,9.08333333 C16.4166667,8.57705833 16.006275,8.16666667 15.5,8.16666667 C14.993725,8.16666667 14.5833333,8.57705833 14.5833333,9.08333333 C14.5833333,9.58960833 14.993725,10 15.5,10 C16.006275,10 16.4166667,9.58960833 16.4166667,9.08333333 Z"
                      id="Shape"
                      fill="#FFFFFF"
                      mask="url(#mask-2)"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
    <div class="provizyon-form__content">
      <p>Dikkat! Bu kurum KDV’siz olup, Tevkifattan muaftır.</p>
      <p class="provizyon-form__content__title">Paket Bilgileri</p>
      <div class="d-flex">
        <table class="table-top w-50 mr-1">
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title">Paket Adı</span
              ><span>:</span><span class="row-text">2022 EKİM AYAKTAN - 3</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Paket Numarası</span
              ><span>:</span><span class="row-text">86729</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Paket Tutarı</span
              ><span>:</span><span class="row-text">2.330,83</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Provizyon Adedi</span
              ><span>:</span><span class="row-text">2</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Paket Durumu</span
              >
              <span>:</span><span class="row-text">Gönderilmeyi Bekliyor</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Paket Oluşturma Tarihi</span
              ><span>:</span><span class="row-text">25.11.2022</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 119px" class="row-title"
                >Sıra Numarası</span
              ><span>:</span><span class="row-text">2022 - 79909</span>
            </td>
          </tr>
        </table>
        <table class="table-top w-50 ml-1">
          <tr style="height: 51px">
            <td>
              <span class="row-title danger-color"
                >Bu alan vakıf tarafından dolduralacaktır!</span
              >
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 100px" class="row-title"
                >İnceleyen Kişi</span
              ><span>:</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 100px" class="row-title"
                >İnceleme Tarihi</span
              ><span>:</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 100px" class="row-title"
                >Onay Gönderen</span
              ><span>:</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 100px" class="row-title"
                >Onaylanan Tutar</span
              ><span>:</span>
            </td>
          </tr>
          <tr>
            <td>
              <span style="min-width: 100px" class="row-title"
                >Kontrol Eden</span
              ><span>:</span>
            </td>
          </tr>
        </table>
      </div>
      <p>
        Bu form, hizmet veren kurum tarafından vakfa gönderilecek faturaların
        alınmış provizyonlarını içermelidir. Bu formu faturalar ile birlikte
        vakfa göndermeniz gerekmektedir.
      </p>
      <p class="provizyon-form__content__title">Provizyon Bilgileri</p>
      <table class="table-bottom w-100 m-b-20">
        <tr>
          <th>Sıra No</th>
          <th>Provizyon No</th>
          <th>Sicil</th>
          <th>Provizyon Sahibi</th>
          <th>T.C. Kimlik No</th>
          <th>Fatura Tarihi</th>
          <th>Fatura No</th>
          <th>Fatura Tutarı</th>
        </tr>
        <tr>
          <td>
            <span>1</span>
          </td>
          <td>
            <span>538005</span>
          </td>
          <td>
            <span>16****</span>
          </td>
          <td>
            <span>Ey*** Be***</span>
          </td>
          <td>
            <span>55*******4</span>
          </td>
          <td>
            <span>21.11.2022</span>
          </td>
          <td>
            <span>ASC2022000051210</span>
          </td>
          <td>
            <span>2.330,83</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>2</span>
          </td>
          <td>
            <span>538005</span>
          </td>
          <td>
            <span>16****</span>
          </td>
          <td>
            <span>Ey*** Be***</span>
          </td>
          <td>
            <span>55*******4</span>
          </td>
          <td>
            <span>21.11.2022</span>
          </td>
          <td>
            <span>ASC2022000051210</span>
          </td>
          <td>
            <span>2.330,83</span>
          </td>
        </tr>
      </table>
      <p class="text-center">Kurum Kaşe/İmza</p>
    </div>
  </div>
</div>
