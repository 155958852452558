<div class="card p-5 m-5">
  <div class="table-responsive m-b-30 col-3">
    <table class="table table-optik-list">
      <thead>
        <tr style="border-bottom: none">
          <th style="border: none">Versiyon Kontrol</th>
          <th style="border: none"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>UI</td>
          <td>1.1</td>
        </tr>
        <tr>
          <td>API</td>
          <td>
            {{ versionApi }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
