<div class="w-100">
  <div class="user-info justify-content-between flex-wrap">
    <div class="card w-30">
      <div class="user-alt-info">
        <div class="d-flex justify-content-between p-2">
          <div class="d-flex">
            <ngx-avatar
              class="b-r-10"
              size="42"
              name="{{ provizyonGetirData?.hastaModelDto.hastaAdSoyad }}"
            ></ngx-avatar>
            <div class="ml-2 mt-auto mb-auto">
              <span class="user-title-name">Hasta Adı Soyadı</span><br />
              <span class="card-heading">{{
                provizyonGetirData?.hastaModelDto.hastaAdSoyad
              }}</span>
            </div>
          </div>

          <div>
            <div>
              <div class="d-flex mt-auto mb-auto">
                <div
                  class="tooltip-card d-inline-flex"
                  style="gap: 15px"
                  *ngIf="
                    provizyonGetirData?.talepTurTanimID != 3 &&
                    provizyonGetirData?.talepTurTanimID != 2
                  "
                >
                  <span
                    *ngIf="doktorSGKAnlasmaliMi === true"
                    style="white-space: nowrap"
                    class="fas fa-exclamation-circle warning-tooltip-icon"
                    placement="right"
                    ngbTooltip="Medula sistemine de giriş yapılmalıdır."
                  ></span>
                </div>
                <p class="ml-1">Üye Durumu</p>
              </div>
              <div style="margin-top: -12px; text-align: right">
                <span class="shy-sg-kli"
                  ><b
                    >SHY
                    <b *ngIf="doktorSGKAnlasmaliMi === true">(SGK'lı)</b></b
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="padding: 15px 30px 20px 30px">
        <div class="d-flex justify-content-between">
          <label class="left-text">T.C. Kimlik No</label>
          <span class="right-text">{{
            provizyonGetirData?.hastaModelDto?.hastaTcKimlikNo
          }}</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Doğum Tarihi</label>
          <span class="right-text">{{
            provizyonGetirData?.hastaModelDto?.hastaDogumTarihi
              | date : "dd.MM.yyyy"
          }}</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Hizmet Alan</label>
          <span class="right-text">{{
            provizyonGetirData?.hastaModelDto?.hizmetAlan
          }}</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Üye Statüsü</label>
          <span class="right-text">{{
            provizyonGetirData?.hastaModelDto?.uyeCalismaDurum
          }}</span>
        </div>
        <hr
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
          *ngIf="
            provizyonGetirData?.talepTurTanimID != 3 &&
            provizyonGetirData?.talepTurTanimID != 2
          "
        />
        <div
          class="d-flex justify-content-between"
          *ngIf="
            provizyonGetirData?.talepTurTanimID != 3 &&
            provizyonGetirData?.talepTurTanimID != 2
          "
        >
          <label class="left-text">SGK Durumu</label>
          <span class="right-text">{{
            doktorSGKAnlasmaliMi === true ? "Anlaşmalı" : "Anlaşmalı Değil"
          }}</span>
        </div>
      </div>
    </div>

    <div class="card w-30">
      <div *ngIf="!provizyonGetirData?.hastaModelDto.vipMi">
        <div
          class="user-alt-info"
          *ngIf="
            provizyonGetirData?.talepTurTanimID == 3 ||
            provizyonGetirData?.talepTurTanimID == 2
          "
        >
          <div class="d-flex justify-content-center p-2 text-center">
            <h2>Provizyon Bilgileri</h2>
          </div>
        </div>
      </div>

      <div
        class="user-alt-info-orange"
        style="
          padding: 20px;
          white-space: nowrap;
          align-items: center;
          text-align: center;
        "
        *ngIf="
          !provizyonGetirData?.hastaModelDto.vipMi &&
          provizyonGetirData?.talepTurTanimID != 3 &&
          provizyonGetirData?.talepTurTanimID != 2
        "
      >
        <div class="d-flex p-2 align-items-center">
          <h2 class="mb-0">Katkı payı üye tarafından ödenecektir!</h2>
        </div>
      </div>
      <div
        class="user-alt-info-vip w-100"
        *ngIf="provizyonGetirData?.hastaModelDto.vipMi"
      >
        <div class="p2 d-flex justify-content-center">
          <span class="alt-text2">
            <span class="alt-text"
              >{{ provizyonGetirData?.hastaModelDto.vipBaslik }}
            </span>
            <span class="alt-text" style="word-break: break-word"
              >{{ newVip }}
            </span>
            <!-- <p>{{ newVip }}</p> -->
            <!-- <span class="alt-text3" *ngIf="newVip[1]?.length != 0">{{
              newVip[1]
            }}</span> -->
          </span>
        </div>
      </div>

      <div style="padding: 15px 30px 20px 30px">
        <div class="d-flex justify-content-between">
          <label class="left-text">Provizyon No</label>
          <span class="right-text">{{ provizyonGetirData?.id }}</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Hizmet Tarihi</label>
          <span class="right-text">{{
            provizyonGetirData?.hizmetTarih | date : "dd.MM.yyy"
          }}</span>
        </div>
        <hr
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
          *ngIf="
            provizyonGetirData?.talepTurTanimID != 3 &&
            provizyonGetirData?.talepTurTanimID != 5
          "
        />
        <div
          class="d-flex justify-content-between"
          *ngIf="
            provizyonGetirData?.talepTurTanimID != 3 &&
            provizyonGetirData?.talepTurTanimID != 5
          "
        >
          <label class="left-text">Değerlendirme Durumu</label>
          <span class="right-text">{{
            provizyonGetirData?.provizyonFaturaDurumTanim?.ad
          }}</span>
        </div>
        <hr
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
          *ngIf="provizyonGetirData?.talepTurTanimID == 3"
        />
        <div
          class="d-flex justify-content-between"
          *ngIf="provizyonGetirData?.talepTurTanimID == 3"
        >
          <label class="left-text">Onay Durumu</label>
          <span class="right-text">{{
            provizyonGetirData?.provizyonDisDurum?.ad
          }}</span>
        </div>
        <hr
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
          *ngIf="provizyonGetirData?.talepTurTanimID == 5"
        />
        <div
          class="d-flex justify-content-between"
          *ngIf="provizyonGetirData?.talepTurTanimID == 5"
        >
          <label class="left-text">Değerlendirme Durumu</label>
          <span class="right-text">{{
            provizyonGetirData?.provizyonYatisDurum?.ad
          }}</span>
        </div>
        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text">Tevkifatlı mı?</label>
          <span class="right-text">
            <div class="checkbox">
              <input
                id="checkbox1"
                type="checkbox"
                disabled
                [checked]="provizyonGetirData?.tevkifatVar"
              />
              <label for="checkbox1"></label>
            </div>
            <!-- {{
            provizyonGetirData?.tevkifatVar == true ? "Evet" : "Hayır"
          }} -->
          </span>
        </div>
      </div>
    </div>

    <div class="card w-30">
      <div style="padding: 30px">
        <div
          class="d-flex justify-content-between"
          *ngIf="doktorSGKAnlasmaliMi === true"
        >
          <label class="left-text">SGK Tutarı</label>
          <h3 class="tutarlar">
            {{ provizyonGetirData?.sgkTutarKDVli | trFormat }}
          </h3>
        </div>
        <hr
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
          *ngIf="
            doktorSGKAnlasmaliMi === true &&
            !provizyonGetirData?.hastaModelDto.vipMi
          "
        />
        <div
          class="d-flex justify-content-between"
          *ngIf="!provizyonGetirData?.hastaModelDto.vipMi"
        >
          <label class="left-text" style="color: #ed6b06; font-size: 18px"
            ><b>SHY Katkı Payı Tutarı</b></label
          >
          <h3 class="tutarlar" style="color: #ed6b06">
            {{ provizyonGetirData?.katkiTutar | trFormat }}
          </h3>
        </div>
        <hr
          *ngIf="
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == true) ||
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == false)
          "
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
        />
        <div
          class="d-flex justify-content-between"
          *ngIf="
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == true) ||
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == false)
          "
        >
          <label class="left-text">Tutar (KDV'siz)</label>
          <h3 class="tutarlar">
            {{ provizyonGetirData?.toplamTutarKDVsiz | trFormat }}
          </h3>
        </div>
        <hr
          *ngIf="
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == true) ||
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == false)
          "
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
        />
        <div class="d-flex justify-content-between">
          <label class="left-text">Hesaplanan KDV (%{{ kdvOran }})</label>
          <h3 class="tutarlar">
            {{ provizyonGetirData?.toplamKDV | trFormat }}
          </h3>
        </div>
        <hr
          *ngIf="
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == true) ||
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == false)
          "
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
        />
        <div
          *ngIf="
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == true) ||
            (kdvOran > 0 && provizyonGetirData?.tevkifatVar == false)
          "
          class="d-flex justify-content-between"
        >
          <label class="left-text">Vergiler Dahil Tutar</label>
          <h3 class="tutarlar">
            {{ provizyonGetirData?.toplamTutarKDVli | trFormat }}
          </h3>
        </div>
        <hr
          *ngIf="kdvOran > 0 && provizyonGetirData?.tevkifatVar == true"
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
        />
        <div
          *ngIf="kdvOran > 0 && provizyonGetirData?.tevkifatVar == true"
          class="d-flex justify-content-between"
        >
          <label class="left-text">Tevkifat Oranı </label>
          <h3 class="tutarlar">{{ tevkifatOran }}/100</h3>
        </div>
        <hr
          *ngIf="kdvOran > 0 && provizyonGetirData?.tevkifatVar == true"
          style="background-color: #ecf3fa; margin-top: -3px; height: 1px"
        />
        <div
          *ngIf="kdvOran > 0 && provizyonGetirData?.tevkifatVar == true"
          class="d-flex justify-content-between"
        >
          <label class="left-text">Ödenecek Tevkifat Tutarı </label>
          <h3 class="tutarlar">
            {{ provizyonGetirData?.odenecekTevkifatTutar | trFormat }}
          </h3>
        </div>

        <hr style="background-color: #ecf3fa; margin-top: -3px; height: 1px" />
        <div class="d-flex justify-content-between">
          <label class="left-text text-danger">Red Tutar (KDV'li)</label>
          <h3 class="text-danger red-tutar">
            {{ provizyonGetirData?.redTutarKDVli | trFormat }}
          </h3>
        </div>

        <div
          class="d-flex justify-content-between odenecek-tutar-bg mt-auto mb-auto"
          style="margin-left: -8px; margin-right: -8px"
        >
          <h2 class="odenecek-tutar">Ödenecek Tutar</h2>
          <h3 class="odenecek-tutar">
            {{ provizyonGetirData?.tutar | trFormat }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</div>
