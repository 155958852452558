<div class="login-main">
  <form class="theme-form">
    <div class="theme-form__top">
      <h2>Halk Bankası Emekli Sandığı Provizyon Sistemi</h2>

      <h4 class="m-0">Yeni Şifre al</h4>
    </div>
    <hr class="card-hr m-3" />
    <div class="theme-form__content">
      <!-- Şifreyi unuttum -->
      <div *ngIf="loginType == 1">
        <form [formGroup]="loginForm">
          <div class="form-group mb-3">
            <label class="label">Kullanıcı Adı</label>
            <input
              type="text"
              [(ngModel)]="kullaniciAdi"
              name="kAdi"
              placeholder="Kullanıcı Adını Giriniz."
              class="form-control"
              required
            />

            <div
              *ngIf="
                loginForm.controls.kAdi.touched &&
                loginForm.controls.kAdi.errors?.required
              "
              class="text text-danger mt-1"
            >
              Kullanıcı Adı boş geçilemez.
            </div>
          </div>
          <div class="form-group mb-3">
            <label class="label">Telefon No son 4 hanesi</label>
            <input
              type="text"
              [(ngModel)]="telSon4Hane"
              name="no4Hane"
              placeholder="Telefon Numaranızın son 4 hanesi"
              class="form-control"
              required
            />
            <div
              *ngIf="
                loginForm.controls.no4Hane.touched &&
                loginForm.controls.no4Hane.errors?.required
              "
              class="text text-danger mt-1"
            >
              Tel no boş geçilemez.
            </div>
          </div>
          <div class="form-group">
            <img src="{{ captchaData }}" />
            <a class="ml-3" (click)="captcha()">
              <i
                class="icon-reload mt-auto mb-auto"
                style="zoom: 1.5; cursor: pointer"
              ></i>
            </a>
            <div>
              <input
                type="text"
                class="mt-3"
                placeholder="Doğrulama Kodu"
                [(ngModel)]="captchaText"
                name="recaptcha"
                required
              />
            </div>
            <div
              *ngIf="
                loginForm.controls.recaptcha.touched &&
                loginForm.controls.recaptcha.errors?.required
              "
              class="text text-danger mt-1"
            ></div>
          </div>

          <div class="form-group mt-2">
            <button
              (click)="sifremiUnuttum()"
              class="btn btn-success btn-block"
              type="button"
              [disabled]="loginForm.invalid"
            >
              <span>Gönder</span>
            </button>
          </div>
        </form>

        <!-- [disabled]="
        telSon4Hane == null ||
        telSon4Hane == '' ||
        kullaniciAdi == null ||
        kullaniciAdi == ''
      " -->
      </div>
      <!-- Şifreyi unuttum  end-->

      <!-- Sms -->

      <div *ngIf="loginType == 2">
        <div class="form-group mb-3">
          <label class="label">Sms Kodunu Giriniz</label>
          <input
            type="text"
            [(ngModel)]="smsKodu"
            placeholder="Sms Kodu"
            class="form-control"
          />
        </div>
        <div class="form-group m-0">
          <button
            (click)="smsKoduDogrulu()"
            class="btn btn-success btn-block"
            type="button"
          >
            <span>Gönder</span>
          </button>
        </div>
      </div>
      <!-- Sms  end-->

      <!-- İlk giris kontrolü -->

      <div *ngIf="loginType == 3 || loginType == 4 || loginType == 5">
        <div class="form-group mb-3">
          <label class="label">Yeni Şifre</label>
          <input
            [(ngModel)]="sifre1"
            type="password"
            placeholder="Yeni Şifre"
            class="form-control"
          />
        </div>
        <div class="form-group mb-3">
          <label class="label">Şifre Tekrar</label>
          <input
            [(ngModel)]="sifre2"
            type="password"
            placeholder="Şifre Tekrarı"
            class="form-control"
          />
        </div>
        <div class="form-group m-0" *ngIf="loginType == 3">
          <button
            (click)="ilkGirisKontrol()"
            class="btn btn-success btn-block"
            type="button"
          >
            <span>Gönder</span>
          </button>
        </div>

        <div class="form-group m-0" *ngIf="loginType == 4">
          <button
            (click)="gunKontrol()"
            class="btn btn-success btn-block"
            type="button"
          >
            <span>Şifremi Güncelle</span>
          </button>
        </div>
        <div class="form-group m-0" *ngIf="loginType == 5">
          <button
            (click)="smsKoduIleSifreGuncelle()"
            class="btn btn-success btn-block"
            type="button"
          >
            <span>Şifremi Güncelle</span>
          </button>
        </div>
      </div>

      <!-- İlk giris kontrolü  end-->
    </div>
  </form>
</div>
