import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "trFormat",
})
export class TrFormatPipe implements PipeTransform {
  transform(value: number): string {
    const formattedValue = value?.toLocaleString("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedValue;
  }
}
